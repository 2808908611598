import loadable from '@loadable/component';
import { type FunctionComponent, useState } from 'react';
import { buildGTMHeaderNotificationsClick } from '../../../helpers/analytics/gtm/event-builders';
import { useTrackEvent } from '../../../hooks/analytics/analytics.hooks';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { ClickableElement } from '../../buttons';
import { Badge } from '../../common-components/badge/badge.component';
import { NotificationsIcon } from '../../svg/icons.component';
import { notificationBellHidden } from './notification-badge.css';

const LoadableNotificationFeed = loadable(
	() => import(/* webpackChunkName: "notification-feed" */ '../notification-feed/notification-feed.component'),
	{
		resolveComponent: ({ NotificationFeed }) => NotificationFeed
	}
);

export const NotificationsBadge: FunctionComponent<{ isHidden?: boolean; isBuildDomain?: boolean; className?: string }> = ({
	isHidden,
	isBuildDomain = true,
	className = ''
}) => {
	const { data: customerData } = useCustomer();
	const trackEvent = useTrackEvent();
	const unreadNotificationCount = customerData?.customer?.unreadNotificationCount ?? 0;
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleClick = () => {
		setIsOpen(!isOpen);
		trackEvent(buildGTMHeaderNotificationsClick());
	};

	return (
		<div className={isHidden ? notificationBellHidden : ''}>
			<ClickableElement ariaLabel="notifications" onClick={handleClick}>
				<Badge count={unreadNotificationCount} className={className}>
					<NotificationsIcon
						className={`${isBuildDomain ? 'f2 theme-grey' : 'f3 theme-primary hover-theme-primary-darker'} nr2 `}
					/>
				</Badge>
			</ClickableElement>
			{isOpen && <LoadableNotificationFeed setIsOpen={setIsOpen} />}
		</div>
	);
};
