import { REGEX_CANADA_VALID_ZIP, REGEX_US_VALID_ZIP } from '../../constants/address/address-form';
import { NO_EMAIL_SUFFIX } from '../../constants/customer';
import type { AddressFormData, NullableAddress } from '../../types/address-form.types';

export const mapDefaultValues = (
	defaultAddress?: NullableAddress | null,
	defaultEmailAddress?: string | null
): Partial<AddressFormData> => {
	return {
		fullName: defaultAddress?.fullName,
		company: defaultAddress?.company,
		addressLine1: defaultAddress?.addressLine1,
		addressLine2: defaultAddress?.addressLine2,
		city: defaultAddress?.city,
		state: defaultAddress?.state,
		zipCode: defaultAddress?.zipCode,
		country: defaultAddress?.country,
		phoneNumber: defaultAddress?.phoneNumber,
		emailAddress: defaultEmailAddress?.includes(NO_EMAIL_SUFFIX) ? '' : defaultEmailAddress,
		defaultAddress: defaultAddress?.defaultAddress
	};
};

export const isFirstAndLastString = (fullName: string): boolean => fullName.trim().split(' ').length >= 2;

export const hasNoAsterisk = (value: string): boolean => !value.includes('*');

export function isZipCodeValid(zipCode: string, usAndCa = false): boolean {
	return Boolean(usAndCa ? REGEX_CANADA_VALID_ZIP.test(zipCode) || REGEX_US_VALID_ZIP.test(zipCode) : REGEX_US_VALID_ZIP.test(zipCode));
}
