import { type FunctionComponent } from 'react';
import { type Maybe, type HomePageImageFieldsFragment } from '../../../__generated__/graphql-client-types';
import { PLACEHOLDER_OPTIONS } from '../../../constants/cloudinary/general';
import { type CloudinaryOptions } from '../../../helpers/cloudinary-helper/cloudinary-helper';
import { type SplashCallToAction } from '../../../types/home.types';
import { LinkButton } from '../../buttons/link-button/link-button.component';
import { Link } from '../../common-components/link/link.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { ctaMobile } from './home-splash.css';
import { translateAlignmentToStyles } from './splash-helpers/splash.helpers';

export type HomeSplashProps = {
	background: HomePageImageFieldsFragment;
	backgroundTracking?: Maybe<string>;
	callToAction: SplashCallToAction;
};

export const HomeSplash: FunctionComponent<HomeSplashProps> = ({ background, backgroundTracking, callToAction }) => {
	const { text, style, desktop, mobile, tracking: foregroundTracking } = callToAction;
	const { url, tracking } = callToAction.link;
	const imageOptions: CloudinaryOptions = {
		width: 1280,
		height: 554,
		quality: 80
	};
	const smallImageOptions: CloudinaryOptions = {
		width: 413,
		height: 179,
		quality: 80
	};
	const splashOptions: CloudinaryOptions = { height: 354, width: 416 };
	const resolvedForegroundTracking = foregroundTracking ? foregroundTracking : tracking;
	const resolvedBackgroundTracking = backgroundTracking ? backgroundTracking : tracking;
	const dynamicCTAAlignmentStyle = translateAlignmentToStyles(callToAction.alignment);

	return (
		<>
			{/* Guided Selling Campaign */}
			<div id="guided-selling-campaign" className="mt3 overflow-hidden"></div>
			{/* large screens */}
			<section
				className="dn flex-ns flex-column items-center w-100 min-h-100 relative pointer bg-theme-white mb3"
				data-testid="Large Splash">
				<div className="flex flex-column items-center overflow-hidden w-100 relative">
					{/* Call To Action */}
					<div className="absolute" style={{ ...dynamicCTAAlignmentStyle }}>
						<div className="flex flex-column items-center justify-center overflow-hidden w-100 pa0 pa5-l relative">
							<div className="absolute z-1" style={{ bottom: '15%', left: '50%', transform: 'translateX(-50%)' }}>
								<div className="dn flex-l">
									<LinkButton buttonStyle={style} size={'DEFAULT'} url={url} analyticsHook={tracking}>
										{text}
									</LinkButton>
								</div>
								<div className="flex dn-l">
									<LinkButton buttonStyle={style} size={'SMALL'} url={url} analyticsHook={tracking}>
										{text}
									</LinkButton>
								</div>
							</div>
							<Link
								url={url}
								ariaLabel="foreground"
								analyticsHook={resolvedForegroundTracking}
								className="flex flex-column items-center overflow-hidden"
								automationHook="landing-page-section">
								<CloudinaryImage
									description={desktop.description}
									publicID={desktop.id}
									className="min-h-100"
									options={splashOptions}
									useDimensions={false}
									placeholderOptions={{
										...splashOptions,
										...PLACEHOLDER_OPTIONS
									}}
									lowQualityPlaceholder={desktop.lowQualityPlaceholder}
								/>
							</Link>
						</div>
					</div>
					{/* Main Image */}
					<Link url={url} analyticsHook={resolvedBackgroundTracking} ariaLabel="background">
						<CloudinaryImage
							description={background.description}
							publicID={background.id}
							options={imageOptions}
							useDimensions={false}
							placeholderOptions={{
								...imageOptions,
								...PLACEHOLDER_OPTIONS
							}}
							lowQualityPlaceholder={background.lowQualityPlaceholder}
						/>
					</Link>
				</div>
			</section>
			{/* mobile and small screens */}
			<section
				className="flex dn-ns flex-column items-center w-100 min-h-100 relative pointer bg-theme-white mb3"
				data-testid="Small Splash">
				{/* Main Image */}
				<div className="flex flex-column items-center overflow-hidden w-100 relative">
					<Link url={url} analyticsHook={resolvedBackgroundTracking} ariaLabel="background">
						<CloudinaryImage
							description={background.description}
							publicID={background.id}
							options={smallImageOptions}
							useDimensions={false}
							placeholderOptions={{
								...smallImageOptions,
								...PLACEHOLDER_OPTIONS
							}}
							lowQualityPlaceholder={background.lowQualityPlaceholder}
						/>
					</Link>
				</div>
				{/* Call To Action */}
				<div className="w-100">
					<div className={`flex flex-column items-center justify-center overflow-hidden w-100 pa0 pa5-l relative ${ctaMobile}`}>
						<div className="absolute z-1" style={{ bottom: '10%', left: '50%', transform: 'translateX(-50%)' }}>
							<LinkButton buttonStyle={style} size={'SMALL'} url={url} analyticsHook={tracking}>
								{text}
							</LinkButton>
						</div>
						<Link
							url={url}
							ariaLabel="foreground"
							analyticsHook={resolvedForegroundTracking}
							className="flex flex-column items-center overflow-hidden w-100">
							<CloudinaryImage
								description={mobile.description}
								publicID={mobile.id}
								className="w-100"
								options={{ width: 825, height: 164, crop: 'fill' }}
								placeholderOptions={{
									width: 825,
									height: 164,
									crop: 'fill',
									...PLACEHOLDER_OPTIONS
								}}
								lowQualityPlaceholder={mobile.lowQualityPlaceholder}
							/>
						</Link>
					</div>
				</div>
			</section>
		</>
	);
};
