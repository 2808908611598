import { type CSSProperties } from 'react';

enum AlignmentPosition {
	TOP_LEFT = 'TOP_LEFT',
	TOP_CENTER = 'TOP_CENTER',
	TOP_RIGHT = 'TOP_RIGHT',
	CENTER_LEFT = 'CENTER_LEFT',
	CENTER = 'CENTER',
	CENTER_RIGHT = 'CENTER_RIGHT',
	BOTTOM_LEFT = 'BOTTOM_LEFT',
	BOTTOM_CENTER = 'BOTTOM_CENTER',
	BOTTOM_RIGHT = 'BOTTOM_RIGHT'
}

export const translateAlignmentToStyles = (position: string): CSSProperties => {
	switch (position) {
		case AlignmentPosition.TOP_LEFT:
			return { top: 0, left: 0 };
		case AlignmentPosition.TOP_CENTER:
			return { top: 0, left: '50%', transform: 'translate(-50%, 0)' };
		case AlignmentPosition.TOP_RIGHT:
			return { top: 0, right: 0 };
		case AlignmentPosition.CENTER_LEFT:
			return { top: '50%', left: 0, transform: 'translate(0, -50%)' };
		case AlignmentPosition.CENTER:
			return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };
		case AlignmentPosition.CENTER_RIGHT:
			return { top: '50%', right: 0, transform: 'translate(0, -50%)' };
		case AlignmentPosition.BOTTOM_LEFT:
			return { bottom: 0, left: 0 };
		case AlignmentPosition.BOTTOM_CENTER:
			return { bottom: 0, left: '50%', transform: 'translate(-50%, 0)' };
		case AlignmentPosition.BOTTOM_RIGHT:
			return { bottom: 0, right: 0 };
	}
	return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };
};
