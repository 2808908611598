import {
	type CarouselFieldsFragment,
	type ExclusiveDealsFieldsFragment,
	type ShopByLookFieldsFragment,
	type BannerFieldsFragment,
	type CarouselItemFieldsFragment,
	type TipsInspirationFieldsFragment,
	type FeaturedBrandsFieldsFragment
} from '../../__generated__/graphql-client-types';
import {
	CAROUSEL_CLOUDINARY_OPTIONS,
	EXCLUSIVE_DEALS_ARTWORK_DOUBLE_CLOUDINARY_OPTIONS,
	EXCLUSIVE_DEALS_ARTWORK_MULTIPLE_CLOUDINARY_OPTIONS,
	EXCLUSIVE_DEALS_ARTWORK_SINGLE_CLOUDINARY_OPTIONS,
	EXCLUSIVE_DEALS_ARTWORK_SINGLE_SMALL_CLOUDINARY_OPTIONS,
	EXCLUSIVE_DEALS_IMAGE_CLOUDINARY_OPTIONS,
	EXCLUSIVE_DEALS_IMAGE_SMALL_CLOUDINARY_OPTIONS,
	SALE_CATEGORIES_CLOUDINARY_OPTIONS
} from '../../constants/home/general';
import { type CloudinaryOptions } from '../../helpers/cloudinary-helper/cloudinary-helper';
import { type HomePageCarouselItem } from '../../helpers/home/home-carousel/home-carousel.helpers';
import {
	type DynamicYieldTemplate,
	type ExclusiveDealsItem,
	type HomeProject,
	type HomeSection,
	type SaleCategoriesItem
} from '../../types/home.types';

export const isCarouselSection = (item: HomeSection): item is CarouselFieldsFragment => {
	return item.__typename === 'HomePageCarousel';
};

export const isDynamicYieldSection = (item: HomeSection): item is DynamicYieldTemplate => {
	return item.__typename === 'DynamicYieldTemplate';
};

export const isExclusiveDealsSection = (item: HomeSection): item is ExclusiveDealsFieldsFragment => {
	return item.__typename === 'HomePageExclusiveDeals';
};

export const isShopByLookSection = (item: HomeSection): item is ShopByLookFieldsFragment => item.__typename === 'HomePageShopByLook';

export const isBannerSection = (item: HomeSection): item is BannerFieldsFragment => {
	return item.__typename === 'HomePageBanner';
};

export const isProjectsSection = (item: HomeSection): item is HomeProject => {
	return item.__typename === 'HomePageProject';
};

export const isGenericCarouselItem = (item: HomePageCarouselItem): item is CarouselItemFieldsFragment => {
	return item.__typename === 'HomePageCarouselItem';
};

export const isSaleCategoriesItem = (item: HomePageCarouselItem): item is SaleCategoriesItem => {
	return item.__typename === 'HomePageSaleCategoriesItem';
};

export const isTipsInspirationSection = (item: HomeSection): item is TipsInspirationFieldsFragment => {
	return item.__typename === 'HomePageInspiration';
};

export const isExclusiveDealsItem = (item: HomePageCarouselItem): item is ExclusiveDealsItem => {
	return item.__typename === 'HomePageExclusiveDealsItem';
};

export const findCloudinaryOptions = (item: HomePageCarouselItem, isMobile = false): CloudinaryOptions => {
	return isSaleCategoriesItem(item)
		? SALE_CATEGORIES_CLOUDINARY_OPTIONS
		: isExclusiveDealsItem(item) && isMobile
		? EXCLUSIVE_DEALS_IMAGE_SMALL_CLOUDINARY_OPTIONS
		: isExclusiveDealsItem(item)
		? EXCLUSIVE_DEALS_IMAGE_CLOUDINARY_OPTIONS
		: CAROUSEL_CLOUDINARY_OPTIONS;
};

export const findExclusiveDealsArtworkImageCloudinaryOptions = (itemsCount: number, isNotSmall: boolean): CloudinaryOptions => {
	return itemsCount === 1 && isNotSmall
		? EXCLUSIVE_DEALS_ARTWORK_SINGLE_CLOUDINARY_OPTIONS
		: itemsCount === 1 && !isNotSmall
		? EXCLUSIVE_DEALS_ARTWORK_SINGLE_SMALL_CLOUDINARY_OPTIONS
		: itemsCount === 2
		? EXCLUSIVE_DEALS_ARTWORK_DOUBLE_CLOUDINARY_OPTIONS
		: EXCLUSIVE_DEALS_ARTWORK_MULTIPLE_CLOUDINARY_OPTIONS;
};

export const isFeaturedBrands = (item: HomeSection): item is FeaturedBrandsFieldsFragment => {
	return item.__typename === 'HomePageFeaturedBrands';
};
