import { type RefObject } from 'react';
import { FOCUSABLE_SELECTOR } from '../../constants/general';
import { isHTMLElement } from '../../utils/dom/dom.utils';
import { isChromatic } from '../general-helper/general-helper';

export const findFirstAndLastFocusable = (element: HTMLElement) => {
	// Prevent Chromatic issues with focusable elements causing flaky results
	if (isChromatic()) {
		return [];
	}
	const allFocusable = element.querySelectorAll(FOCUSABLE_SELECTOR);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return [allFocusable[0], allFocusable[allFocusable.length - 1]] as [HTMLElement, HTMLElement];
};

/**
 * Looks for a focusable within the selector or ref you provide and focuses on the
 * first focusable found.  Defaults to the popup presenters node.
 */
export const focusFirstFocusable = <T extends HTMLElement>(options: { selector?: string; ref?: RefObject<T> } = {}) => {
	const { selector = '#popup-root', ref } = options;

	const container = ref ? ref.current : document.querySelector<HTMLElement>(selector);
	if (container) {
		const [focusable] = findFirstAndLastFocusable(container);
		if (focusable) {
			focusable.focus();
			return true;
		}
	}
	return false;
};

/**
 * Returns the currently "focused" element.
 */
export function findFocusedElement(): HTMLElement | null {
	return isHTMLElement(document.activeElement) ? document.activeElement : null;
}

/**
 * Event handler that traps focus within the element that it is attached to.
 */
export function focusTrapEventHandler(this: HTMLElement, event: KeyboardEvent) {
	const [firstFocusable, lastFocusable] = findFirstAndLastFocusable(this);
	if (event.shiftKey) {
		// reverse tab
		if (event.target === firstFocusable) {
			event.preventDefault();
			lastFocusable?.focus();
		}
	} else {
		// forward tab
		if (event.target === lastFocusable) {
			event.preventDefault();
			firstFocusable?.focus();
		}
	}
}
