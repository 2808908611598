import { type FunctionComponent, type ReactNode } from 'react';
import { BaseButton, type BaseButtonProps } from '../base-button/base-button.component';
import { BUTTON_STYLE_MAP, type ButtonSize, type ButtonStyle } from '../button.types';

/**
 * IconButton takes all props base button does except `className`, selects the square size, and imports buttonStyle from styleButton
 */
export type IconButtonProps = {
	buttonStyle?: ButtonStyle;
	size?: Extract<ButtonSize, 'SQUARE' | 'CIRCLE' | 'ICON'>;
	children: ReactNode;
	ariaLabel: string;
} & Omit<BaseButtonProps, 'size'>;

export const IconButton: FunctionComponent<IconButtonProps> = ({
	size = 'CIRCLE',
	buttonStyle = 'PRIMARY',
	children,
	disabled,
	className,
	...remainingProps
}) => (
	<BaseButton
		size={size}
		className={`fw4 ${disabled && buttonStyle === 'INTERNAL_ICON' ? 'bn' : BUTTON_STYLE_MAP[buttonStyle]} ${className} `}
		disabled={disabled}
		{...remainingProps}>
		{children}
	</BaseButton>
);
