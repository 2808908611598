import { type FunctionComponent, useRef, useState } from 'react';
import { DOHERTY_THRESHOLD, FEATURE_FLAGS } from '../../../constants/general';
import { useContactInfo, useContactMethodStatus } from '../../../hooks/apollo/site/site.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import type { LinkStyleColor } from '../../common-components/link/link.types';
import { StyledLink } from '../../common-components/link/styled-link.component';
import { Popover } from '../../common-components/popover/popover.component';
import { CallIcon } from '../../svg/icons.component';

const PHONE_CLASSES = 'hover-theme-primary h-100 h-auto-ns w-auto-ns mr2-ns order-1 order-0-ns ph2 ph0-ns mh1 mh0-ns dib';

// TODO: EFDC-16856: reduce use of custom css for header
const PhoneToggle: FunctionComponent<{
	phoneNumber: string;
	label?: string;
	iconClass?: string;
	color?: LinkStyleColor;
	linkClass?: string;
}> = ({ phoneNumber, color, label, iconClass = '', linkClass = '' }) => {
	return (
		<StyledLink url={`tel:${phoneNumber}`} className={`${PHONE_CLASSES} ${linkClass}`} color={color ?? 'grey'} underline={false}>
			<div className="flex flex-column flex-row-ns items-center justify-center h-100 mr3-ns">
				<CallIcon className={`${iconClass ?? 'f2 f5-ns'}`} />
				<div className="dn dib-ns">{label ? label : phoneNumber}</div>
				<div className="dib dn-ns">Call</div>
			</div>
		</StyledLink>
	);
};

export type CallCenterLinkProps = {
	// depending on context (product pages), a custom phone can be rendered
	phoneOverride?: string;
	customLabel?: string;
	iconClass?: string;
	color?: LinkStyleColor;
	linkClass?: string;
};

/**
 * CallCenterLink - renders a link to the call center or display available phone hours in a popover
 */
export const CallCenterLink: FunctionComponent<CallCenterLinkProps> = ({ phoneOverride, color, customLabel, iconClass, linkClass }) => {
	const [isPhoneHoursOpen, setIsPhoneHoursOpen] = useState(false);
	const closeMenuTimer = useRef(0);

	// this is a killswitch for cases when we have issues with phone providers.
	const isCallCenterEnabled = useFeature(FEATURE_FLAGS.CONTACT_CENTER_ENABLED);

	const isOpen = useContactMethodStatus('phone');
	const { phone: phoneInfo } = useContactInfo();
	const phoneNumber = phoneOverride ?? phoneInfo.number;

	const mouseEnter = () => {
		clearTimeout(closeMenuTimer.current);
		setIsPhoneHoursOpen(true);
	};
	const mouseLeave = () => {
		closeMenuTimer.current = window.setTimeout(() => setIsPhoneHoursOpen(false), DOHERTY_THRESHOLD);
	};

	if (!isCallCenterEnabled) {
		return null;
	}

	return isOpen ? (
		<PhoneToggle phoneNumber={phoneNumber} color={color} label={customLabel} iconClass={iconClass} linkClass={linkClass} />
	) : (
		<Popover
			isVisible={isPhoneHoursOpen}
			setIsVisible={setIsPhoneHoursOpen}
			toggleElement={
				<div data-testid="phone-toggle-wrapper" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
					<PhoneToggle phoneNumber={phoneNumber} color={color} label={customLabel} iconClass={iconClass} linkClass={linkClass} />
				</div>
			}
			direction="bottom">
			<div data-testid="phone-popover" className="pa1 f6 lh-copy" style={{ width: '11rem' }}>
				<div className="b">Mon-Fri</div>
				<div>
					{phoneInfo.weekdayHours.startTime} - {phoneInfo.weekdayHours.endTime} PST
				</div>
				<div className="b">Sat &amp; Sun</div>
				<div>
					{phoneInfo.weekendHours.startTime} - {phoneInfo.weekendHours.endTime} PST
				</div>
				{phoneInfo.exceptions.map((exception, index) => (
					<div key={index}>
						<div className="b">{exception.date}</div>
						<div>
							{exception.fullDayClosure ? (
								<>Closed</>
							) : (
								<>
									{exception.adjustedHours?.startTime} - {exception.adjustedHours?.endTime} PST
								</>
							)}
						</div>
					</div>
				))}
			</div>
		</Popover>
	);
};
