import { type FunctionComponent } from 'react';
import { ACCOUNT_PRO_LINK } from '../../../constants/links';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { Link } from '../../common-components/link/link.component';
import { StyledLink } from '../../common-components/link/styled-link.component';
import { ProBadgeRenderer } from '../../common-components/pro-badge-renderer/pro-badge-renderer.component';
import { proBadge } from './pro-signup-link.css';

const OmniProSignup: FunctionComponent = () => (
	<StyledLink
		url={ACCOUNT_PRO_LINK}
		className={'dn flex-l flex-row items-center mr3 hover-theme-primary omniPrimaryHover'}
		color="grey"
		underline={false}>
		<span className="f6 lh-copy" data-testid="omni-header-pro-signup">
			Are you a Pro? Get
		</span>
		<ProBadgeRenderer className={`${proBadge} ph1 di v-mid theme-pro omni-theme-grey`} />
		<span className="f6 lh-copy">Pricing</span>
	</StyledLink>
);

const BuildProSignup: FunctionComponent = () => (
	<Link url={ACCOUNT_PRO_LINK} className="dn flex-l flex-row mh2 pt1 hover-theme-primary" color="grey">
		<div className="f7 b mb1" data-testid="ab-projects-in-header-pro-banner">
			Are you a Pro?
		</div>
		<div className="f7 ml1">
			<span className="di v-mid">Get</span>
			<ProBadgeRenderer className={`${proBadge} ph1 theme-pro di v-mid`} />
			<span className="di v-mid">Pricing</span>
		</div>
	</Link>
);

export const ProSignupLink: FunctionComponent<{ isBuild?: boolean }> = ({ isBuild }) => {
	const { hasAccount } = useCustomer();
	return hasAccount ? null : <>{isBuild ? <BuildProSignup /> : <OmniProSignup />}</>;
};
