import loadable from '@loadable/component';
import { type FunctionComponent } from 'react';
import { useEmployee } from '../../hooks/apollo/employee/employee.hooks';

const LoadedInternalMenu = loadable(() => import(/* webpackChunkName: "internal-menu" */ './internal-menu.component'), {
	resolveComponent: ({ InternalMenu: InternalMenuComponent }) => InternalMenuComponent
});

export const LoadableInternalMenu: FunctionComponent = () => {
	const { employee } = useEmployee();
	return employee.isAuthenticated ? <LoadedInternalMenu /> : null;
};
