import loadable from '@loadable/component';
import { type FunctionComponent, type PropsWithChildren } from 'react';
import { type SiteDomain } from '../../__generated__/graphql-client-types';
import { useSiteInfo } from '../../hooks/apollo/site/site.hooks';

const OmniHomeStyle = loadable(() => import(/* webpackChunkName: "omni-home-style" */ './style-omni-home.component'), {
	resolveComponent: ({ StyleOmniHome }) => StyleOmniHome
});

const BuildStyle = loadable(() => import(/* webpackChunkName: "build-style" */ './style-build.component'), {
	resolveComponent: ({ StyleBuild }) => StyleBuild
});

const LoadStyle: FunctionComponent<{ domain: SiteDomain }> = ({ domain }) => (domain === 'BUILD' ? <BuildStyle /> : <OmniHomeStyle />);

export const StyleLoader: FunctionComponent<PropsWithChildren> = ({ children }) => {
	const {
		data: { siteDomain },
		loading
	} = useSiteInfo();

	// apply a domain specific class so descendants can use them in selectors
	const domainStyle = siteDomain === 'BUILD' ? 'build' : 'omni';

	return (
		<>
			{!loading && <LoadStyle domain={siteDomain} />}
			<div className={domainStyle}>{children}</div>
		</>
	);
};
