import { type FunctionComponent, type PropsWithChildren, useState } from 'react';
import { HelpCircleIcon } from '../../svg/icons.component';
import { Popover } from '../popover/popover.component';
import { helpIconPopoverWidth } from './helpIcon.css';

export type HelpIconProps = {
	/**
	 * classes to apply for the popover container
	 */
	className?: string;

	/**
	 * classes to apply for the icon
	 */
	iconClassName?: string;

	/**
	 * classes to apply for the toggle element
	 */
	toggleElementClassName?: string;

	direction?: 'top' | 'bottom';
};

export const HelpIcon: FunctionComponent<PropsWithChildren<HelpIconProps>> = ({
	children,
	className = helpIconPopoverWidth,
	iconClassName,
	toggleElementClassName,
	direction = 'top'
}) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<Popover
			toggleElementClassName={toggleElementClassName}
			popOverClassName={className}
			isVisible={isOpen}
			setIsVisible={setIsOpen}
			direction={direction}
			toggleElement={<HelpCircleIcon className={`pointer ${iconClassName || ''}`} />}>
			{children}
		</Popover>
	);
};
