export const BILL_ADDRESS_LINE_MAX_LEN = 60;
export const SHIP_ADDRESS_LINE_MAX_LEN = 30;
export const COMPANY_MAX_LEN = 40;
export const CITY_MAX_LEN = 50;
export const FULL_NAME_MAX_LEN = 50;

export const US_ZIP_LENGTH = 5;
export const CANADA_ZIP_LENGTH = 7;

export const States = new Set([
	'AL',
	'AK',
	'AZ',
	'AR',
	'AA',
	'AE',
	'AP',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FL',
	'GA',
	'GU',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'OH',
	'OK',
	'OR',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VA',
	'VI',
	'WA',
	'WV',
	'WI',
	'WY',
	// Below are Canada provinces
	'AB',
	'BC',
	'MB',
	'NB',
	'NL',
	'NT',
	'NS',
	'NU',
	'ON',
	'PE',
	'QC',
	'SK',
	'YT'
]);
// REGEX: looks for 5 digits with `-` followed by 4(#####-####) or 5 digits or 9
export const REGEX_US_VALID_ZIP = /^(\d{5}-\d{4}|\d{5}|\d{9})$/;

// REGEX: Canadian postal code such as M3C 1R7 (or M3C1R7 without space)
export const REGEX_CANADA_VALID_ZIP = /^([A-Z]\d[A-Z] ?\d[A-Z]\d)$/;
