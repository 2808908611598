import { Fragment, type FunctionComponent, type PropsWithChildren } from 'react';
import { HOMEPAGE_LINK } from '../../constants/links';
import { generateDataSelector } from '../../helpers/general-helper/general-helper';
import { StyledLink } from '../common-components/link/styled-link.component';
import { ChevronRightIcon, HomeIcon } from '../svg/icons.component';

export type Breadcrumb = {
	name: string;
	url?: string;
};

export type BreadcrumbsProps = {
	breadcrumbs: Breadcrumb[];
	homePageLink?: string; // used for instances when we wish to override the home icon link such as shop by look
	className?: string;
};

type BreadcrumbItemProp = {
	breadcrumb?: Breadcrumb;
	position: string;
	isLast?: boolean;
};

const BreadcrumbItem: FunctionComponent<PropsWithChildren<BreadcrumbItemProp>> = ({ children, position, breadcrumb = {}, isLast }) => {
	let breadcrumbElement: React.ReactNode | HTMLSpanElement | null = null;

	if (breadcrumb) {
		if (!isLast && breadcrumb.url) {
			breadcrumbElement = (
				<StyledLink itemProp="item" underlineHover={true} url={breadcrumb.url} className="pv0-ns pv2">
					<span itemProp="name">{breadcrumb.name}</span>
				</StyledLink>
			);
		} else {
			breadcrumbElement = (
				<span itemProp="name" aria-current="page">
					{breadcrumb.name}
				</span>
			);
		}
	}

	return (
		<li
			itemScope={true}
			itemProp="itemListElement"
			itemType="https://schema.org/ListItem"
			data-automation={generateDataSelector('breadcrumb', breadcrumb?.name)}>
			{breadcrumbElement}
			<meta itemProp="position" content={position} />
			{children}
		</li>
	);
};

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ breadcrumbs, homePageLink, className = '' }) => {
	if (!breadcrumbs || !breadcrumbs.length) {
		return;
	}
	return (
		<nav aria-label="Breadcrumb" className={className}>
			<ol
				className="h-auto-ns h2 flex flex-wrap list ma0 pa0 f6 items-center"
				itemScope={true}
				itemType="https://schema.org/BreadcrumbList">
				<BreadcrumbItem position="1">
					<StyledLink itemProp="item" url={homePageLink ? homePageLink : HOMEPAGE_LINK} ariaLabel="Return to Home">
						<HomeIcon className="f4" />
						<meta itemProp="name" content="Home" />
					</StyledLink>
				</BreadcrumbItem>
				{breadcrumbs.map((breadcrumb, index) => (
					<Fragment key={breadcrumb.name}>
						<span className="mh1 mt1" aria-hidden={true}>
							<ChevronRightIcon className="theme-grey-light" />
						</span>
						<BreadcrumbItem
							position={(index + 2).toString()}
							breadcrumb={breadcrumb}
							isLast={index + 1 === breadcrumbs.length}
						/>
					</Fragment>
				))}
			</ol>
		</nav>
	);
};
