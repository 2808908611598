import { forwardRef } from 'react';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { checkboxLabel, checkmark, inner, input, outer } from './checkbox.css';

export type BaseCheckboxProps = {
	analyticsHook?: string;
	automationHook?: string;
	isChecked?: boolean;
	name?: string;
	onChange?: (data: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
	onTransitionEnd?: () => void;
	readOnly?: boolean;
	required?: boolean;
	tabIndex?: 0 | -1;
	value?: string;
	disabled?: boolean;
	id: string;
	wrapText?: boolean;
	flexAlignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
	noLabel?: boolean;
};

export type CheckboxPropsWithLabel = BaseCheckboxProps & {
	ariaLabel?: string;
	label: string | React.ReactElement;
};
export type CheckboxPropsWithAriaLabel = BaseCheckboxProps & {
	ariaLabel: string;
	label?: string | React.ReactElement;
};

const commonTachyons = 'ma0 pa0 pointer relative';

export type CheckboxProps = CheckboxPropsWithLabel | CheckboxPropsWithAriaLabel;
/**
 * Creates a styled, font scalable, accessible, checkbox.  Native input[type="checkbox"] vary from browser to browser and do not allow
 * restyling or scaling.
 *
 * @see http://bonsai-ui.com/ (👈 shameless self promotion - Kyle)
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
	(
		{
			ariaLabel,
			analyticsHook,
			automationHook,
			isChecked,
			label,
			name,
			onChange,
			onKeyDown,
			onTransitionEnd,
			readOnly,
			required,
			tabIndex,
			value,
			disabled,
			id,
			wrapText,
			flexAlignItems = 'center',
			noLabel = false
		},
		ref
	) => {
		const textWrapClass = wrapText ? 'ws-normal' : 'nowrap';
		return (
			<label
				className={`${commonTachyons} ${checkboxLabel} ${textWrapClass} flex flex-row items-${flexAlignItems} justify-start`}
				htmlFor={id}>
				<span className={`${commonTachyons} ${outer} ${textWrapClass} dib lh-copy`}>
					{/* tslint:disable-next-line:react-a11y-required react-a11y-role-supports-aria-props
					Rule conflict. We are meeting the aria-required rule at runtime */}
					<input
						aria-checked={isChecked}
						aria-label={ariaLabel}
						checked={isChecked}
						className={`${input} absolute left-0 z-unset pointer o-0 top-0 bottom-0 right-0 w-100 h-100 ma0`}
						data-testid={name}
						disabled={disabled}
						aria-disabled={disabled}
						id={id}
						name={name}
						onChange={onChange}
						readOnly={readOnly}
						required={required}
						tabIndex={tabIndex}
						type="checkbox"
						value={value}
						data-automation={generateDataSelector('checkbox', automationHook)}
						data-analytics={generateDataSelector('checkbox', analyticsHook)}
						ref={ref}
						onKeyDown={onKeyDown}
					/>
					<span className={`${inner} relative top-0 left-0 db bg-theme-white br1`} />
					<span data-testid={`checkmark-${value}`} className={checkmark} onTransitionEnd={onTransitionEnd} />
				</span>
				{!noLabel && <span className={`pl2 source-sans-pro color-inherit`}>{label}</span>}
			</label>
		);
	}
);
