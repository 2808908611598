import loadable from '@loadable/component';
import { type FunctionComponent, type PropsWithChildren } from 'react';
import { DefaultLayout } from './default/default-layout.component';

export type LayoutControllerProps = {
	layoutType?: 'simple' | 'minimized';
	backgroundStyle?: 'default' | 'grey';
};

const LoadableMinimizedLayout = loadable(
	() => import(/* webpackChunkName: "minimized-layout" */ './minimized/minimized-layout.component'),
	{
		resolveComponent: ({ MinimizedLayout }) => MinimizedLayout
	}
);

const LoadableSimpleLayout = loadable(() => import(/* webpackChunkName: "simple-layout" */ './simple/simple-layout.component'), {
	resolveComponent: ({ SimpleLayout }) => SimpleLayout
});

export const LayoutController: FunctionComponent<PropsWithChildren<LayoutControllerProps>> = ({
	layoutType,
	backgroundStyle,
	children
}) => {
	if (layoutType === 'simple') {
		return <LoadableSimpleLayout>{children}</LoadableSimpleLayout>;
	} else if (layoutType === 'minimized') {
		return <LoadableMinimizedLayout>{children}</LoadableMinimizedLayout>;
	} else {
		return <DefaultLayout backgroundStyle={backgroundStyle}>{children}</DefaultLayout>;
	}
};
