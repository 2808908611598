export const SITE_PATH = 'https://www.build.com';
export const FH_SITE_PATH = 'https://www.fergusonhome.com';
/*
 * Social media links
 */
export const BUILD_FACEBOOK_LINK = 'https://www.facebook.com/buildwithferguson';
export const BUILD_INSTAGRAM_LINK = 'https://www.instagram.com/buildwithferguson/';
export const BUILD_PINTEREST_LINK = 'https://www.pinterest.com/build/';
export const BUILD_YOUTUBE_LINK = 'https://www.youtube.com/user/builddotcom';

export const FERG_FACEBOOK_LINK = 'https://www.facebook.com/FergusonShowrooms';
export const FERG_INSTAGRAM_LINK = 'https://www.instagram.com/fergusonshowrooms/';
export const FERG_PINTEREST_LINK = 'https://www.pinterest.com/fergusonshowrooms/';
export const FERG_YOUTUBE_LINK = 'https://www.youtube.com/FergusonEnterprises';
export const FERG_LINKEDIN_LINK = 'https://www.linkedin.com/company/ferguson-official';
export const FERG_HOUZZ_LINK =
	'https://www.houzz.com/hznb/professionals/kitchen-and-bath-fixtures/ferguson-bath-kitchen-and-lighting-gallery-pfvwus-pf~310501118';

/*
 * App store links
 */
export const APP_STORE_LINK = 'https://itunes.apple.com/us/app/shop-build.com/id1053662668?ls=1&mt=8';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.buildapp';

/*
 * Account links
 */
export const ACCOUNT_CREATE_LINK = '/account/create';
export const ACCOUNT_DETAILS_LINK = '/account/details';
export const ACCOUNT_LOGIN_LINK = '/account/login';
export const ACCOUNT_LOGOUT_LINK = '/account/logout';
export const ACCOUNT_ORDERS_LINK = '/account/orders';
export const ACCOUNT_PRO_LINK = '/proaccount';
export const ACCOUNT_PROJECTS_LINK = '/account/projects';
export const ACCOUNT_RETURNS_LINK = '/account/returns';
export const ACCOUNT_OPEN_ACCOUNT_LINK = '/account/openaccount';
export const ACCOUNT_SALES_REP_LINK = '/account/salesrep';
export const ACCOUNT_STORE_CREDIT_LINK = '/account/storecredit';
export const ACCOUNT_SUBSCRIPTION_LINK = '/account/manage_catalog_subscription';
export const ACCOUNT_LINK = '/account';
export const ACCOUNT_ADDRESSES_LINK = '/account/addresses';
export const ACCOUNT_PAYMENTS_LINK = '/account/payment';
export const ACCOUNT_SAVED_CARTS_LINK = '/account/savedcarts';
export const ACCOUNT_TRADE_CREDIT_LINK = '/account/tradecredit';
export const ACCOUNT_COMPANY_DETAILS_LINK = '/account/companydetails';
export const ACCOUNT_RESET_PASSWORD = '/account/reset-password';
export const ACCOUNT_NOTIFICATION_SETTINGS = '/account/details#notifications';

/*
 * Article links
 */
export const ARTICLE_PAGE_LINK = '/:slug/a:articleId([0-9]+)';

/*
 * Orders links
 */
export const ORDER_RETURN_LINK = '/account/order/:orderNumber([0-9]+)/return';
export const ORDER_CANCEL_LINK = '/account/order/cancel/:orderNumber([0-9]+)';
export const ORDER_SPECSHEETS_LINK = '/account/order/specSheets/:orderNumber([0-9]+)';
export const ORDER_SUMMARY_LINK = '/order/summary';
export const SET_UP_RETURN_LINK = '/order/return';
export const ORDER_SHIPMENTS_LINK = '/order/shipments';
export const ECRM_ORDER_SHIPMENTS_LINK = '/account/order/shipments/:orderNumber([0-9]+)';
export const ORDER_TRACK_LINK = '/order/track';
export const ORDER_STATUS_LINK = '/order/status';
export const ORDER_LINK = '/account/order/:orderNumber([0-9]+)';
export const RETURN_LINK = '/account/returns/:returnNumber([0-9]+)';
export const RETURN_LABEL_LINK = '/account/returns/label/:returnNumber([0-9]+)';
export const ORDER_RECEIPT_LINK = '/order/receipt';
export const ECRM_ORDER_RECEIPT_LINK = '/account/order/receipt/:orderNumber([0-9]+)';
export const DOWNLOAD_ORDERS_LINK = '/account/orders/createReceipt';
export const ORDER_INVOICES_BY_PROJECT = '/pdf/project/orders/invoice/:projectId([0-9]+)';
export const ORDER_INVOICES_BY_DATE_RANGE = '/pdf/orders/invoice';
export const CSV_ORDER_INVOICES_DOWNLOAD = '/orders/download';

/*
 * Support links
 */
export const SUPPORT_ABOUT_LINK = '/support/about_us';
export const SUPPORT_AFFILIATES_LINK = '/support/affiliates';
export const BUILD_SUPPORT_EMAIL_LINK = 'mailto:cs@build.com';
export const FERGHOME_SUPPORT_EMAIL_LINK = 'mailto:support@fergusonhome.com';
export const SUPPORT_EMAIL_US_LINK = '/support/email_us';
export const SUPPORT_FAQ_LINK = '/support/faq';
export const SUPPORT_FAQ_SHIPPING_LINK = '/support/faq#shipping-order-status';
export const SUPPORT_NONSTOCK_LINK = '/support/nonstock';
export const SUPPORT_PRICING_LINK = '/support/special_pricing';
export const SUPPORT_RETURNS_LINK = '/support/returns';
export const SUPPORT_SHIPPING_LINK = '/support/shipping_policy';
export const SUPPORT_SUBSCRIBE_LINK = '/support/subscribe-unsubscribe';
export const HOW_TO_MEASURE_UOM_LINK = '/how-to-measure-flooring/a179';
export const SERVICE_NOW_LINK = 'https://wolseley.service-now.com/servicehub?id=sc_cat_item&sys_id=60f06debdb9aa9508fc3ebb5059619d7';
export const SUPPORT_HOME_LINK = '/support/home';

/*
 * Privacy links
 */
export const PRIVACY_CA_RIGHTS_LINK = '/support/security#california-rights';
export const PRIVACY_RIGHTS_LINK = 'https://ferguson.bigidprivacy.cloud/consumer/#/dMf02ovZbN/form-1000';
export const PRIVACY_SECURITY_LINK = '/support/security';
export const PRIVACY_TERMS_LINK = '/support/terms';
export const PRIVACY_TERMS_SALE = '/support/tos';
export const PRIVACY_COOKIE_LINK = '/support/security#cookies';

/*
 * Showroom links
 */
export const SHOWROOM_LINK = '/showroom';
export const SHOWROOM_SEARCH_LINK = '/showroom/search';
export const SHOWROOM_BOOK_APPT_IFRAME_LINK = '/showroom-book-appt-iframe';
export const SHOWROOM_APPT_MANAGEMENT_LINK = '/showroom/appointment-management';
export const SHOWROOM_LOCATION_LINK = '/showroom/branch/:branchPageUrl';

/*
 * PRO links
 */
export const PRO_EXCLUSIVE_DEALS_LINK = '/pro-exclusive-deals';
export const PRO_SIGNUP_LINK = '/account/prosignup';

/*
 * Miscellaneous internal links
 */
export const BRANDS_LINK = '/brands/c109293';
export const CAREERS_LINK = 'https://ferguson.wd1.myworkdayjobs.com/Ferguson_Experienced';
export const CATALOG_LINK = '/catalog';
export const COMPARE_LINK = '/compare';
export const COUPONS_LINK = '/savings';
export const HOMEPAGE_LINK = '/';
export const LEARNING_CENTER_LINK = '/learn';
export const MANUFACTURER_INFO_LINK = '/internal/manufacturer-information/:manufacturerId([0-9]+)';
export const VENDOR_INFO_LINK = '/internal/vendor-information/:vendorId([0-9]+)';
export const PRODUCT_SUMMARY_LINK = '/product/summary';
export const PROJECT_ONBOARDING_LINK = '/projects/onboarding';
export const PROJECT_INVITE_LINK = '/projectsinvite';
export const REBATES_LINK = '/rebates';
export const SHOP_ALL_CATEGORIES_LINK = '/shop-all-departments/c130789';
export const SHOP_BY_LOOK_LINK = '/shop-by-look';
export const SITE_MAP_LINK = '/sitemap';
export const PROJECT_TOOL_LINK = '/account/projects';
export const PROJECTS_LINK = '/projects';
export const ACCOUNT_PROJECTS_DETAILS = '/account/projects/:projectId([0-9]+)';
export const PROJECT_PORTFOLIO_LINK = '/account/project/:projectId([0-9]+)/portfolio/create';
export const FREIGHT_IN_PERSON_DELIVERY = '/freight-instructions/in-person-delivery-acceptance';
export const FREIGHT_REMOTE_DELIVERY = '/freight-instructions/remote-delivery-acceptance';
export const FREIGHT_DELIVERY_VIDEO_INSTRUCTIONS = '//build.wistia.com/medias/d7obv4lfqc';
export const INTERNAL_FIND_CUSTOMER_LINK = '/internal/customer/find';
export const SALES_FORCE_FIND_CUSTOMER_LINK = '/sfdc/customer';
export const SUBMIT_REVIEW_TERMS_LINK = '/submit-review-terms';
export const JUST_FOR_YOU = '/just-for-you';
export const APPLIANCE_PACKAGE_BUILDER_LINK = '/appliance-package-builder';

/*
 * Miscellaneous external links
 */
export const BAZAAR_VOICE_SCRIPT_BASE_URL = 'https://apps.bazaarvoice.com/deployments/morehome/build';
export const FERGUSON_CORPORATE_SITE = 'https://corporate.ferguson.com/';
export const NEWLEAF_TERMS_LINK = 'https://www.newleafsc.net/ferguson/terms/';
export const TRILOGIE_BID_DOCS = 'https://mydigitalspace.sharepoint.com/sites/OmnichannelTraining/SitePages/Import-Bid-to-Build.com.aspx';
export const LOGGING_IN_AS_A_CUSTOMER_DOCS =
	'https://mydigitalspace.sharepoint.com/sites/OmnichannelTraining/SitePages/Logging-in-as-a-Customer.aspx';
export const WORKFRONT =
	'https://ferguson.my.workfront.com/requests/new?activeTab=tab-new-helpRequest&projectID=5e21fcfa0263fac870d97fcc1024c66e&path=';
export const SOLR_FALLBACK_DOCUMENTATION =
	'https://mydigitalspace.sharepoint.com/sites/FERG-Digital/SitePages/Search-Fallback-Mode-Documentation.aspx';

export const FULL_LWC_SANDBOX_LINK = 'https://bcom--full.sandbox.my.site.com';
export const UAT_LWC_SANDBOX_LINK = 'https://bcom--uat.sandbox.my.site.com';
export const PROJECT_INTERVIEW_BOOKING_LINK = 'https://outlook.office365.com/book/BuildProjectsTool@ferguson.com';

/*
 * Cart/Checkout links
 */
export const CART_LINK = '/checkout/cart';
export const ORDER_CART_LINK = '/app/api/cart/generateSessionCart/:orderNumber([0-9]+)/:siteName';
export const QUOTE_CART_LINK = '/checkout/quote';
export const COMBINED_SPECSHEETS_LINK = '/cart/spec-sheets';
export const UPLOAD_SPECIAL_PRODUCT_SPEC_SHEET_LINK = '/cart/special-product/spec-sheet';
export const BEGIN_CHECKOUT_LINK = '/checkout/begin';
export const CHECKOUT_PAGE_LINK = '/checkout/onepage';
export const CHECKOUT_RECEIPT_LINK = '/checkout/receipt';
export const CHECKOUT_OPEN_ACCOUNT_REVIEW = '/checkout/openaccount/review';
export const OPEN_ACCOUNT_ORDER_INVOICE = '/openaccount/orderinvoice';
export const ADD_CREDIT_CARD = '/account/add_card';
export const OPEN_ACCOUNT_RECEIPT_LINK = '/checkout/openaccount/receipt/:openAccountPaymentId([0-9]+)';
export const START_AMAZON_PAY_LINK = '/checkout/amazon-pay/start';
export const COMPLEX_ORDER_PROJECT_LINK = '/checkout/complex-order-project';
/*
 * Support Links
 */
export const SUPPORT_HELP_COOKIES_LINK = '/support/help#cookies';

/*
 * SSO Links
 */
export const SSO_LOGIN_LINK = '/sso/init';
export const SSO_LOGOUT_LINK = '/sso/logout';

export const COLLECTIONS_ROUTE = '/collection/:manufacturerName/:collectionName';
export const SEARCH_ROUTE = '/search';
export const CATEGORY_ROUTE = '/:slug/c:category([0-9]+)';
export const PRODUCT_DETAILS_ROUTE = '/:slug/s:familyId([0-9]+)';
