import { type FunctionComponent, type PropsWithChildren } from 'react';
import { Route } from 'react-router-dom';

type Props = {
	code: number;
};

/**
 * This component allows you to set a response status code from within
 * a component during a server side render. Useful for returning status
 * codes of 404 when the data for a valid route is not found.
 * Ex: Invalid Product Id
 */
export const Status: FunctionComponent<PropsWithChildren<Props>> = ({ code, children }) => (
	<Route
		// tslint:disable-next-line
		render={({ staticContext }) => {
			// There is no `staticContext` on the client
			if (staticContext) {
				staticContext.statusCode = code;
			}
			return children;
		}}
	/>
);
