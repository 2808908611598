import { type FunctionComponent, useEffect, useState } from 'react';

export type LoadingProps = {
	delay?: number;
};

const DEFAULT_DELAY = 166; // ms

/**
 * Renders the loading indicator or "spinner".  It depends on ".busy" css class
 * which is responsible for the loading style, animation, and scaling
 * We want to load the .busy css in global and not a css module so it is
 * immediately available in all cases.
 *
 * @see http://bonsai-ui.com/ (👈 shameless self promotion - Kyle)
 */
export const Loading: FunctionComponent<LoadingProps> = ({ delay = DEFAULT_DELAY }) => {
	const [showLoading, setShowLoading] = useState(false);

	const timer = setTimeout(() => {
		setShowLoading(true);
	}, delay);

	useEffect(() => {
		return () => {
			clearTimeout(timer);
		};
	});

	if (!showLoading) {
		return null;
	}
	return <div className="busy center tc" aria-busy="true" aria-live="polite" data-testid="loadingComponent" />;
};

/**
 * Renders a centered Loading component that should be reused for full "page"
 * content loading to prevent inconsistencies in position or size
 */
export const PageLoading: FunctionComponent = () => (
	<div className="vh-100" data-testid="pageLoadingContainer">
		<div className="w-100 relative flex items-center justify-center" style={{ top: '33%', fontSize: '6rem' }}>
			<Loading />
		</div>
	</div>
);
