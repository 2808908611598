import { useCookies } from '@fergdigitalcommerce/fergy-react-components';
import loadable from '@loadable/component';
import { type FunctionComponent, type PropsWithChildren, createContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { type LocationFieldsFragment } from '../../../client/__generated__/graphql-client-types';
import { COOKIE_COMPARE_PRODUCTS, COOKIE_COMPARE_SHOW } from '../../constants/cookies';
import { MAX_NUMBER_OF_COMPARED_PRODUCTS } from '../../constants/general';

import { makeUnique } from '../../helpers/general-helper/general-helper';
import { useCustomer } from '../../hooks/apollo/customer/customer.hooks';

export const LoadableCompareDrawer = loadable(
	() => import(/* webpackChunkName: "compare-drawer" */ '../../components/product-components/compare-drawer/compare-drawer.component'),
	{
		resolveComponent: ({ CompareDrawer }) => CompareDrawer
	}
);

export const CONTEXT_COOKIE_SEP = '_';

export type CompareContextState = Readonly<{
	isOpen: boolean;
	variantIds: string[];
	location?: LocationFieldsFragment;
	isUserOnComparePage: boolean;
	isComparing: (variantId: string) => boolean;
	setIsOpen: (isOpen: boolean) => void;
	addVariantIds: (addedVariantIds: string[]) => boolean;
	removeVariantIds: (removedVariantIds: string[]) => void;
	swapVariantIds: (currentVariantId: string, newVariantId: string) => void;
	clear: () => void;
	toggleProductCompare: (variantId: string) => boolean;
}>;

const initialState: CompareContextState = {
	isOpen: false,
	variantIds: [],
	isUserOnComparePage: true,
	isComparing: () => false,
	setIsOpen: () => {},
	addVariantIds: () => false,
	removeVariantIds: () => {},
	swapVariantIds: () => {},
	clear: () => {},
	toggleProductCompare: () => false
};

export const CompareContext = createContext<CompareContextState>(initialState);

export const CompareProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
	// any state updates should be done with this helper
	// to ensure our state ref is kept in sync
	const updateState = (newState: CompareContextState) => {
		setCookie(COOKIE_COMPARE_PRODUCTS, newState.variantIds.join(CONTEXT_COOKIE_SEP));
		// if previous state was 0 and we have added a product lets open the drawer
		if (newState.variantIds.length > 0 && stateRef.current.variantIds.length === 0 && componentDidMount.current) {
			newState = { ...newState, isOpen: true };
		}

		// maintain a ref to state so we can ensure our API
		// has a current ref to the latest state
		stateRef.current = newState;

		setState(newState);
	};

	const defaultState = {
		...initialState,
		isComparing: (variantId: string) => {
			return stateRef.current.variantIds.includes(variantId);
		},
		setIsOpen: (isOpen: boolean) => updateState({ ...stateRef.current, isOpen }),
		addVariantIds: (addedVariantIds: string[]): boolean => {
			const currentVariantIds = stateRef.current.variantIds;
			if (currentVariantIds.length + addedVariantIds.length <= MAX_NUMBER_OF_COMPARED_PRODUCTS) {
				const newVariantIds = makeUnique([...currentVariantIds, ...addedVariantIds]);
				updateState({ ...stateRef.current, variantIds: newVariantIds });
				return true;
			}
			return false;
		},
		removeVariantIds: (removedVariantIds: string[]) => {
			const newProductIds = stateRef.current.variantIds.filter((productId) => !removedVariantIds.includes(productId));
			const newIsOpen = stateRef.current.isOpen && newProductIds.length > 0;
			updateState({
				...stateRef.current,
				isOpen: newIsOpen,
				variantIds: newProductIds
			});
		},
		swapVariantIds: (currentVariantId: string, newVariantId: string) => {
			const swapIndex = stateRef.current.variantIds.indexOf(currentVariantId);
			const newVariantIds = stateRef.current.variantIds.slice();
			newVariantIds.splice(swapIndex, 1, newVariantId);
			updateState({ ...stateRef.current, variantIds: newVariantIds });
		},
		clear: () => {
			updateState({
				...stateRef.current,
				isOpen: false,
				variantIds: []
			});
		},
		/**
		 * If product in comparison, removes it.  If not, adds it.  If comparison already full,
		 * returns false (and doesn't add it).  Otherwise returns true
		 */
		toggleProductCompare: (variantId: string): boolean => {
			if (state.isComparing(variantId)) {
				// STOP comparing the product
				state.removeVariantIds([variantId]);
			} else {
				// START comparing the product
				return state.addVariantIds([variantId]);
			}
			return true;
		}
	};

	const [state, setState] = useState(defaultState);
	const stateRef = useRef(state);
	const componentDidMount = useRef(false);
	const { getCookie, setCookie } = useCookies();
	const { pathname } = useLocation();
	const { data: customerData } = useCustomer();
	const customerLocation: LocationFieldsFragment | undefined = (customerData?.customer || {}).location;

	// populate state on mount
	useEffect(() => {
		// verify our state hasn't already been populated by api
		if (stateRef.current.variantIds.length === 0) {
			const productsFromCookie = getCookie(COOKIE_COMPARE_PRODUCTS) || undefined;
			const showFromCookie = getCookie(COOKIE_COMPARE_SHOW);
			updateState({
				...stateRef.current,
				variantIds: productsFromCookie ? productsFromCookie.split(CONTEXT_COOKIE_SEP) : [],
				isOpen: showFromCookie === 'true' && productsFromCookie !== undefined,
				isUserOnComparePage: pathname.includes('/compare')
			});
		}

		if (!componentDidMount.current) {
			componentDidMount.current = true;
		}
	}, []);

	// Update location if zip code changes in customer session.
	// Products subjected to be compared may have sale restrictions based on zipcode.
	useEffect(() => {
		const { current } = stateRef;
		if (customerLocation && current.location && current.location.zipCode !== customerLocation.zipCode) {
			updateState({ ...current, location: { ...customerLocation } });
		}
	}, [customerLocation?.zipCode]);

	useEffect(() => {
		// make sure the drawer is either open or close depending on the new state of isOpen
		if (stateRef.current.isOpen) {
			setCookie(COOKIE_COMPARE_SHOW, 'true');
		} else {
			setCookie(COOKIE_COMPARE_SHOW, 'false');
		}
	}, [state]);

	return (
		<CompareContext.Provider value={state}>
			{!state.isUserOnComparePage && state.isOpen && <LoadableCompareDrawer onClose={() => state.setIsOpen(false)} />}
			{children}
		</CompareContext.Provider>
	);
};
