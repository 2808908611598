import { logError } from 'fergy-core-react-logging';
import { type FunctionComponent, cloneElement } from 'react';
import { updateUsabillaCustomVariables } from '../../../../third-party/setup-usabilla-button';
import { type AnalyticsEvent, AnalyticsHelper } from '../../../helpers/analytics/analytics.helper';
import { TextButton, type TextButtonProps } from '../text-button/text-button.component';

export type ComposeUsabillaButtonProps = {
	children: JSX.Element;
	customAction?: { action: string; campaignName: string };
	analyticsEvent?: AnalyticsEvent;
};

/**
 * Renders a child button with an onClick that triggers the usabilla feedback form when clicked in production only.
 */
export const ComposeUsabillaButton: FunctionComponent<ComposeUsabillaButtonProps> = ({ children, customAction, analyticsEvent }) => {
	const openUsabillaForm = () => {
		updateUsabillaCustomVariables();
		if (customAction) {
			window.usabilla_live(customAction.action, customAction.campaignName);
		} else {
			window.usabilla_live('click');
		}

		if (analyticsEvent) {
			AnalyticsHelper.track(analyticsEvent.data).catch(logError);
		}
	};

	const onClick = () => (window.usabilla_live ? openUsabillaForm() : alert('not available in this environment'));

	return cloneElement(children, { onClick });
};

export type UsabillaTextButtonProps = Omit<TextButtonProps, 'onClick'>;

/**
 * Renders a text button that sets up and triggers the usabilla feedback form when clicked in production only.
 */
export const UsabillaTextButton: FunctionComponent<UsabillaTextButtonProps> = ({ children, ...props }) => (
	<ComposeUsabillaButton>
		<TextButton {...props}>{children}</TextButton>
	</ComposeUsabillaButton>
);
