import loadable from '@loadable/component';
import { type FunctionComponent } from 'react';
import { doesWindowExist } from '../../utils/dom/dom.utils';

const LoadedLiveChat = loadable(() => import(/* webpackChunkName: "live-chat" */ './live-chat.component'), {
	resolveComponent: ({ LiveChat: LiveChatComponent }) => LiveChatComponent
});

export const LoadableLiveChat: FunctionComponent = () => {
	return doesWindowExist() ? <LoadedLiveChat /> : null;
};
