import { type FunctionComponent } from 'react';
import { useCustomer } from '../../hooks/apollo/customer/customer.hooks';
import { DashboardWidgets } from './dashboard-widgets/dashboard-widgets.component';

type HomeDashboardContainerProps = {
	isHomePageDashboardCookieSetToFalse: boolean;
};

export const HomeDashboardContainer: FunctionComponent<HomeDashboardContainerProps> = ({ isHomePageDashboardCookieSetToFalse }) => {
	const { data, hasAccount } = useCustomer();
	const customerId = data?.customer.id;
	const customerName = data?.customer.profile.firstName;
	return hasAccount && customerId ? (
		<DashboardWidgets
			customerName={customerName || undefined}
			isHomePageDashboardCookieSetToFalse={isHomePageDashboardCookieSetToFalse}
		/>
	) : null;
};
