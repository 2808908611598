import { logError } from 'fergy-core-react-logging';
import { type FunctionComponent, cloneElement, forwardRef, useState } from 'react';
import { type FieldError, type FieldErrors } from 'react-hook-form';
import { type AnalyticsEvent, AnalyticsHelper } from '../../../helpers/analytics/analytics.helper';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { ClickableElement } from '../../buttons';
import { MaybeLabel } from '../../common-components/label/label.component';
import { ReportIcon, VisibilityIcon } from '../../svg/icons.component';
import { INPUT_SIZE_MAP, type InputSize } from './input.types';

export type BaseTextInputProps = {
	autoCapitalize?: string;
	autoCorrect?: string;
	autoComplete?: string;
	automationHook?: string;
	analyticsHook?: string;
	className?: string; // classes for the container around the input
	defaultValue?: string;
	description?: string;
	disabled?: boolean;
	icon?: JSX.Element;
	id?: string;
	inputClassName?: string; // classes for the input element
	inputMode?: InputMode;
	invalid?: boolean;
	invalidMessage?: string | FieldError | FieldErrors;
	maxLength?: number;
	max?: string | number;
	message?: JSX.Element;
	min?: string | number;
	name?: string;
	onBlur?: (data: React.FocusEvent<HTMLInputElement>) => void;
	omitBaseClasses?: boolean;
	onChange?: (data: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (data: React.FocusEvent<HTMLInputElement>) => void;
	onKeyDown?: (data: React.KeyboardEvent<HTMLInputElement>) => void;
	onSubmit?: (data: React.FormEvent<HTMLElement>) => void;
	pattern?: string;
	placeholder?: string;
	required?: boolean;
	step?: number;
	testId?: string;
	type?: string;
	ariaLabel?: string;
	value?: string | number;
	// Analytics data that will sent upon clicking or focus into the input.
	analyticsEvent?: AnalyticsEvent;
	readOnly?: boolean;
	borderDark?: boolean;
	size?: InputSize;
	isDynamic?: boolean;
	leading?: JSX.Element;
	trailing?: JSX.Element;
};

export type InputMode = 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';

export type TextInputProps = BaseTextInputProps & {
	ariaLabel: string;
};

export type PasswordInputProps = Omit<BaseTextInputProps, 'type'> & {
	label: string;
	id: string;
};

export type InputIconProps = {
	isInvalid: boolean;
};

export const InputMessage: FunctionComponent<Pick<BaseTextInputProps, 'invalid' | 'invalidMessage' | 'description'>> = ({
	invalid,
	invalidMessage,
	description
}) => {
	if (invalid && invalidMessage) {
		return (
			<div className={`f7 mt1 theme-error flex items-center gc1`} data-testid="inputMessage">
				<ReportIcon className="f5 minw1" /> <>{invalidMessage}</>
			</div>
		);
	} else if ((!invalid && description) || (invalid && !invalidMessage && description)) {
		return (
			<div className={`f7 mt1 theme-grey flex items-center gc1`} data-testid="inputMessage">
				{invalid && <ReportIcon className="f5 theme-error" />}
				{description}
			</div>
		);
	} else {
		return <></>;
	}
};

const BaseTextInput = forwardRef<HTMLInputElement, BaseTextInputProps>(
	(
		{
			className = '',
			description,
			icon,
			id,
			invalid,
			invalidMessage,
			omitBaseClasses,
			required = false,
			testId,
			type = 'text',
			automationHook,
			analyticsHook,
			analyticsEvent,
			onFocus,
			borderDark,
			size = 'DEFAULT',
			isDynamic,
			leading = <></>,
			trailing = <></>,
			ariaLabel,
			inputClassName = 'ph3',
			...remainingProps
		},
		ref
	) => {
		let classes = `input-reset input br2 f5 ph3 ba w-100 truncate ${className} ${INPUT_SIZE_MAP[size]}`;
		let dynamicClasses = `input-reset br2 ba w-100 truncate flex items-stretch ${className}`;
		if (invalid && !omitBaseClasses) {
			classes = `${classes} b--theme-error`;
			dynamicClasses = `${dynamicClasses} b--theme-error`;
		} else if (!omitBaseClasses) {
			classes = `${classes} theme-grey-darker ${borderDark ? 'b--theme-grey-darker' : 'b--theme-grey-light'} `;
			dynamicClasses = `${dynamicClasses} theme-grey-darker ${borderDark ? 'b--theme-grey-darker' : 'b--theme-grey-light'} `;
		} else {
			classes = className;
			dynamicClasses = className;
		}

		const focusHandler = analyticsEvent
			? (e: React.FocusEvent<HTMLInputElement>) => {
					if (onFocus) {
						onFocus(e);
					}
					AnalyticsHelper.track(analyticsEvent.data).catch(logError);
			  }
			: onFocus;

		return isDynamic ? (
			<div className="flex flex-column">
				{/* tslint:disable-next-line: react-a11y-required  We are abiding by this rule at runtime */}
				<div className={dynamicClasses} data-testid="input-container">
					{leading}
					<input
						id={id}
						className={`truncate bw0 w-100 ph3 color-inherit ${inputClassName} ${INPUT_SIZE_MAP[size]}`}
						type={type}
						aria-required={required}
						required={required}
						data-testid={testId}
						data-automation={generateDataSelector('input', automationHook)}
						data-analytics={generateDataSelector('input', analyticsHook)}
						onFocus={focusHandler}
						ref={ref}
						aria-label={ariaLabel}
						{...remainingProps}
					/>
					{icon}
					{trailing}
				</div>
				<InputMessage description={description} invalid={invalid} invalidMessage={invalidMessage} />
			</div>
		) : (
			<>
				{/* tslint:disable-next-line: react-a11y-required  We are abiding by this rule at runtime */}
				<input
					id={id}
					className={classes}
					type={type}
					aria-required={required}
					required={required}
					data-testid={testId}
					data-automation={generateDataSelector('input', automationHook)}
					data-analytics={generateDataSelector('input', analyticsHook)}
					onFocus={focusHandler}
					ref={ref}
					{...remainingProps}
				/>
				{icon}
				<InputMessage description={description} invalid={invalid} invalidMessage={invalidMessage} />
			</>
		);
	}
);

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ ariaLabel, ...remainingProps }, ref) => {
	return <BaseTextInput {...remainingProps} aria-label={ariaLabel} ref={ref} />;
});

export type DynamicTextInputProps = Omit<BaseTextInputProps, 'leading' | 'trailing' | 'ariaLabel'> & {
	boldLabel?: boolean;
	helpTextTooltip?: React.ReactElement | string;
	leadingConstant?: string;
	trailingConstant?: string;
	padIcon?: boolean;
	iconOnClick?: () => void;
} & (
		| {
				label: string; // if label specified, aria-label is optional
				ariaLabel?: string;
		  }
		| {
				label?: string;
				ariaLabel: string;
		  }
	);

export const DynamicTextInput = forwardRef<HTMLInputElement, DynamicTextInputProps>(
	(
		{
			className = '',
			id,
			label,
			helpTextTooltip,
			required,
			icon,
			ariaLabel,
			iconOnClick,
			disabled,
			boldLabel,
			leadingConstant,
			trailingConstant,
			borderDark,
			padIcon = true,
			...remainingProps
		},
		ref
	) => {
		const renderIcon: JSX.Element =
			icon && iconOnClick ? (
				<ClickableElement
					disabled={disabled}
					className={`${padIcon ? 'ph3' : ''} self-center flex items-center`}
					ariaLabel={`${ariaLabel}-icon`}
					onClick={iconOnClick}>
					{icon}
				</ClickableElement>
			) : icon ? (
				cloneElement(icon, {
					className: `${icon.props.className} ${padIcon ? 'ph3' : ''} self-center`,
					ariaLabel,
					onClick: iconOnClick,
					disabled
				})
			) : (
				<></>
			);

		const constantClassName = `ph2 bg-theme-grey-lighter br2 f6 theme-grey-darker flex items-center ${
			borderDark ? 'b--theme-grey-darker' : 'b--theme-grey-light'
		}`;

		const leading = leadingConstant ? <div className={`${constantClassName} br--left br`}>{leadingConstant}</div> : <></>;
		const trailing = trailingConstant ? <div className={`${constantClassName} br--right bl`}>{trailingConstant}</div> : <></>;

		return (
			<MaybeLabel
				className={className}
				label={label}
				id={id}
				required={required}
				helpTextTooltip={helpTextTooltip}
				boldLabel={boldLabel}>
				<div className="relative w-100">
					<BaseTextInput
						id={id}
						className={label ? undefined : className}
						required={required}
						icon={renderIcon}
						ref={ref}
						isDynamic={true}
						trailing={trailing}
						leading={leading}
						disabled={disabled}
						borderDark={borderDark}
						ariaLabel={ariaLabel}
						{...remainingProps}
					/>
				</div>
			</MaybeLabel>
		);
	}
);

/**
 * @deprecated Use the DynamicTextInput component instead
 */
export const TextInputWithLabel = DynamicTextInput;

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>((props, ref) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const togglePassword = (): void => {
		setIsPasswordVisible(!isPasswordVisible);
	};
	const inputType = isPasswordVisible ? 'text' : 'password';
	const iconTheme = isPasswordVisible ? 'theme-primary' : 'theme-grey';

	return (
		<DynamicTextInput
			icon={<VisibilityIcon className={`${iconTheme} f4`} />}
			iconOnClick={togglePassword}
			type={inputType}
			{...props}
			ref={ref}
		/>
	);
});
