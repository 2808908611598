import { type FunctionComponent } from 'react';
import { ACCOUNT_LINK } from '../../../constants/links';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { useIsBuildDomain } from '../../../hooks/apollo/site/site.hooks';
import { Badge } from '../../common-components/badge/badge.component';
import { Link } from '../../common-components/link/link.component';
import type { LinkStyleColor } from '../../common-components/link/link.types';
import { PersonIcon } from '../../svg/icons.component';

// TODO: EFDC-16856: reduce use of custom css for header
export const MobileAccountLink: FunctionComponent = () => {
	const { data: customerData } = useCustomer();
	const unreadNotificationCount = customerData?.customer?.unreadNotificationCount ?? 0;

	const isBuild = useIsBuildDomain();

	const color: LinkStyleColor = isBuild ? 'grey' : 'primary';
	const personIconClasses = 'f3 omni-f5 omniMobileAccountPaddingBottom';

	return (
		<Link
			url={ACCOUNT_LINK}
			className="dib dn-ns h-auto-ns w-auto-ns ph2 omniHomeHeaderIcon omniHomeNavHeight100 omniHomeAccountItem"
			color={color}>
			<div className="tc omniHomeMobileNavItemContent justify-center">
				<Badge count={unreadNotificationCount} hideCount={true} noMargin={true} className="omniMobileAccountBadge">
					<PersonIcon className={personIconClasses} />
				</Badge>
				<div>Account</div>
			</div>
		</Link>
	);
};
