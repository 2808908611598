import { useQuery } from '@apollo/client';
import { type FunctionComponent, useEffect } from 'react';
import { type MostRecentOrderSummaryQuery, type MostRecentOrderSummaryQueryVariables } from '../../../__generated__/graphql-client-types';
import { HomeDashboardWidgetsEnum } from '../../../constants/home';
import { buildGTMWidgetsDisplayed, buildGTMNoWidgetsDisplayed } from '../../../helpers/analytics/gtm/event-builders';
import { useTrackEvent } from '../../../hooks/analytics/analytics.hooks';
import { HOMEPAGE_DASHBOARD } from '../../../queries/homepage-dashboard/homepage-dashboard.queries';
import { doesWindowExist } from '../../../utils/dom/dom.utils';
import { widgetGap } from '../home-dashboard.css';
import { DashboardCartItems } from './dashboard-cart-items/dashboard-cart-items.component';
import { DashboardOrderTracking } from './dashboard-order-tracking/dashboard-order-tracking.component';

export type DashboardWidgetsProps = {
	customerName?: string;
	isHomePageDashboardCookieSetToFalse: boolean;
};

export const DashboardWidgets: FunctionComponent<DashboardWidgetsProps> = ({ isHomePageDashboardCookieSetToFalse }) => {
	const { data, loading } = useQuery<MostRecentOrderSummaryQuery, MostRecentOrderSummaryQueryVariables>(HOMEPAGE_DASHBOARD, {
		skip: !doesWindowExist()
	});
	const trackEvent = useTrackEvent();
	const orderTrackingData = data?.mostRecentOrderSummary;
	const currentInCartItems = data?.currentInCartItemsSummary?.products || [];

	useEffect(() => {
		if (data && !isHomePageDashboardCookieSetToFalse) {
			if (orderTrackingData && currentInCartItems.length) {
				// fire event for both widgets
				trackEvent(buildGTMWidgetsDisplayed(HomeDashboardWidgetsEnum.ORDERS_AND_CART_ITEMS));
			} else if (orderTrackingData && currentInCartItems.length === 0) {
				// fire event for just ORDERS
				trackEvent(buildGTMWidgetsDisplayed(HomeDashboardWidgetsEnum.ORDERS));
			} else if (currentInCartItems.length && !orderTrackingData) {
				// fire event for just CART ITEMS
				trackEvent(buildGTMWidgetsDisplayed(HomeDashboardWidgetsEnum.CART_ITEMS));
			} else {
				return;
			}
		}
	}, [data]);

	useEffect(() => {
		// This is for users within the A/B test who have ab-homepage-dashboard cookie set to false
		// TODO: remove this later
		if (data && isHomePageDashboardCookieSetToFalse) {
			if (currentInCartItems.length && !orderTrackingData) {
				// fire event for just CART ITEMS
				trackEvent(buildGTMNoWidgetsDisplayed(HomeDashboardWidgetsEnum.CART_ITEMS));
			} else if (orderTrackingData && currentInCartItems.length === 0) {
				// fire event for just ORDERS
				trackEvent(buildGTMNoWidgetsDisplayed(HomeDashboardWidgetsEnum.ORDERS));
			} else if (currentInCartItems.length && orderTrackingData) {
				// fire event for both ORDERS AND CART ITEMS
				trackEvent(buildGTMNoWidgetsDisplayed(HomeDashboardWidgetsEnum.ORDERS_AND_CART_ITEMS));
			} else {
				return;
			}
		}
	}, [data]);

	return (orderTrackingData || currentInCartItems?.length) && !loading && !isHomePageDashboardCookieSetToFalse ? (
		<div
			className={`flex flex-column flex-row-ns flex-wrap ${widgetGap} content-between bg-theme-grey-lighter ph2 pv4 pa4-ns`}
			data-testid="home-dashboard">
			{orderTrackingData && <DashboardOrderTracking {...orderTrackingData} />}
			{currentInCartItems.length > 0 && <DashboardCartItems cartItems={currentInCartItems} />}
		</div>
	) : null;
};
