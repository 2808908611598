import { type PropsWithChildren, type FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import { AnalyticsContextProvider } from '../../../contexts/analytics-context/analytics.context';
import { useSetRumViewName } from '../../../hooks/datadog/rum.hooks';
import { type VersionedRouteProps } from '../../../routes/routes';
import { AuthenticatedRoute } from '../../authenticated-route/authenticated-route.component';
import { EmployeeOnlyRoute } from '../../employee-only-route/employee-only-route.component';
import { LayoutController } from '../layout.controller';

const RouteOrAuthRoute: FunctionComponent<PropsWithChildren<VersionedRouteProps>> = ({
	requiresAuthentication,
	requiresAccount,
	children,
	requiresEmployeeAuthentication,
	...remainingProps
}) => {
	const isSensativeRoute = requiresAccount || requiresAuthentication;
	const isSoftLogin = requiresAccount && !requiresAuthentication;

	if (isSensativeRoute) {
		return (
			<AuthenticatedRoute isSoftLogin={isSoftLogin} {...remainingProps}>
				{children}
			</AuthenticatedRoute>
		);
	} else if (requiresEmployeeAuthentication) {
		return <EmployeeOnlyRoute {...remainingProps}>{children}</EmployeeOnlyRoute>;
	} else {
		return <Route {...remainingProps}> {children}</Route>;
	}
};

export const RouteWithLayout: FunctionComponent<VersionedRouteProps> = ({ layout, component, ...remainingProps }) => {
	const { ddRum, path, backgroundStyle } = remainingProps;
	useSetRumViewName(ddRum?.rumViewNameSetByChild ? ddRum : ddRum?.rumViewName || (Array.isArray(path) ? path[0] : path) || 'Unknown');
	if (component) {
		// Type Juggling to make component types match
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const Component: FunctionComponent = component as FunctionComponent;
		return (
			<RouteOrAuthRoute {...remainingProps}>
				<AnalyticsContextProvider>
					<LayoutController layoutType={layout} backgroundStyle={backgroundStyle}>
						<Component />
					</LayoutController>
				</AnalyticsContextProvider>
			</RouteOrAuthRoute>
		);
	} else {
		return null;
	}
};
