import { type FunctionComponent } from 'react';
import { CART_LINK } from '../../../constants/links';
import { useCartHeader } from '../../../hooks/apollo/cart-header/cart-header.hooks';
import { Link } from '../../common-components/link/link.component';
import type { LinkStyleColor } from '../../common-components/link/link.types';
import { CartIcon } from '../../svg/icons.component';
import { cartIconStyles, cartQuantityLeft } from './cart-link.css';

type CartQuantityIndicatorProps = {
	quantity: number;
	isMinimizedHeader?: boolean;
};

const CartQuantityIndicator: FunctionComponent<CartQuantityIndicatorProps> = ({ quantity, isMinimizedHeader }) => {
	const isOver99 = quantity > 99;
	const modifiedFontSize = isOver99 ? `f7` : '';

	return (
		<div className={`h2 tc ${isMinimizedHeader ? '' : 'omniHomeCartIcon'}`}>
			<div
				className={`relative top-0 omniHomeCartPosition-ns
					omniCartQuantityPosition
				 ${modifiedFontSize} ${cartQuantityLeft} ${isMinimizedHeader ? '' : 'omni-f5-ns omni-f7'}`}
				data-automation="header-cart-quantity-amount">
				{isOver99 ? '99+' : quantity}
			</div>
			<CartIcon className={`f2 ${cartIconStyles} ${isMinimizedHeader ? '' : 'omniCartIcon'}`} />
		</div>
	);
};

export type CartLinkProps = {
	isMobile?: boolean;
	isBuild?: boolean;
	isMinimizedHeader?: boolean;
};

// TODO: EFDC-16856: reduce use of custom css for header
export const CartLink: FunctionComponent<CartLinkProps> = ({ isMobile = false, isBuild = true, isMinimizedHeader }) => {
	const { quantity } = useCartHeader();
	let themeColor: LinkStyleColor;

	if (!isBuild || isMinimizedHeader) {
		themeColor = 'primary';
	} else {
		themeColor = isMobile ? 'white' : 'grey';
	}

	let linkStyle = '';
	if (isBuild && isMobile) {
		linkStyle = 'dn-ns bg-theme-primary pa2 order-2 h-100';
	}

	return (
		<Link
			url={CART_LINK}
			className={`${linkStyle} flex flex-column justify-center ${isMinimizedHeader ? '' : 'omniHomeCartItem omniMobileCartDisplay'}`}
			color={themeColor}
			automationHook={`header-cart${isMobile ? '-mobile' : ''}`}
			ariaLabel={`View ${quantity} items currently in cart`}>
			<CartQuantityIndicator quantity={quantity} isMinimizedHeader={isMinimizedHeader} />
			{!isMinimizedHeader && !isBuild && isMobile && <span className={`f7 lh-title ml2 omniHomeMobileNavCartText`}>Cart</span>}
		</Link>
	);
};
