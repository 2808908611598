import { type FunctionComponent } from 'react';
import { Redirect, type RedirectProps, Route } from 'react-router-dom';
import { findLiveRoute } from '../helpers/routing/route.helpers';
import { PageLoading } from './common-components/loading/loading.component';

type Props = {
	status?: number;
};

/**
 * Allows one to redirect with a specific status code. By default it will use 302.
 */
export const RedirectWithStatus: FunctionComponent<Props & RedirectProps> = ({ status, ...redirectProps }) => (
	<Route
		// tslint:disable-next-line
		render={({ staticContext }) => {
			// There is no `staticContext` on the client
			if (staticContext) {
				staticContext.statusCode = status;
			} else {
				const url = redirectProps.to.toString();
				const isRouteLive = Boolean(findLiveRoute(url));
				if (!isRouteLive) {
					// If the route isn't "live", ensure we don't use
					// react-router (client side) as the page could be hosted by node-store.
					// Even for routes that exist on RBS, a non-live route
					// could also be part of an a/b test, so we don't want a SPA nav here.
					window.location.replace(url);
					return <PageLoading />;
				}
			}
			return <Redirect {...redirectProps} />;
		}}
	/>
);
