import { type FunctionComponent } from 'react';
import { type LinkProps } from '../../common-components/link/link.component';
import type { LinkStyleColor } from '../../common-components/link/link.types';
import { StyledLink } from '../../common-components/link/styled-link.component';
import { type BaseButtonProps } from '../base-button/base-button.component';
import { BUTTON_SIZE_MAP, BUTTON_STYLE_MAP, type ButtonStyle } from '../button.types';
import { linkButtonDisabled } from './link-button.css';

export type LinkButtonProps = {
	buttonStyle?: Extract<ButtonStyle, 'PRIMARY' | 'SECONDARY' | 'INTERNAL'>;
	size?: 'SMALL' | 'DEFAULT' | 'LARGE';
	color?: LinkStyleColor;
	disabled?: boolean;
} & Omit<BaseButtonProps, 'className' | 'testId'> &
	LinkProps;

export const LinkButton: FunctionComponent<LinkButtonProps> = ({
	url,
	buttonStyle = 'PRIMARY',
	color = 'white',
	size = 'DEFAULT',
	disabled,
	...remainingProps
}) => {
	const disabledClasses = `disableClickEvents ${linkButtonDisabled}`;
	const buttonClasses = `link-button pointer fw4 ba br2 w-inherit tc dib lh-normal ${disabled ? disabledClasses : ''} ${
		BUTTON_STYLE_MAP[buttonStyle]
	} ${BUTTON_SIZE_MAP[size]}`;

	return <StyledLink url={url} aria-disabled={disabled} className={buttonClasses} color={color} underline={false} {...remainingProps} />;
};
