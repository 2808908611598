import { type FunctionComponent } from 'react';
import { Switch } from 'react-router-dom';
import { type VersionedRouteProps, redirects, routes } from '../routes/routes';
import { RouteWithLayout } from './layouts/routed/routed-with-layout.component';
import { RedirectWithStatus } from './redirect-w-status';
import { StyleLoader } from './style-loader/style-loader.component';

function generateKeyForRoute(props: VersionedRouteProps): string {
	const { path } = props;
	let key: string;
	if (typeof path === 'string') {
		key = path;
	} else {
		key = path ? path.toString() : 'INVALID ROUTE';
	}
	return key;
}
export const App: FunctionComponent = () => {
	const appRoutes = routes.map((props) => {
		return <RouteWithLayout key={generateKeyForRoute(props)} {...props} />;
	});
	const appRedirects = redirects.map(({ from, to, status }: any) => (
		<RedirectWithStatus key={`${from}-${to}`} from={from} to={to} status={status} />
	));
	return (
		<StyleLoader>
			<Switch>
				{appRoutes}
				{appRedirects}
			</Switch>
		</StyleLoader>
	);
};
