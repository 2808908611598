import { type DetailedHTMLProps, type FunctionComponent, type MetaHTMLAttributes } from 'react';
import { Helmet } from 'react-helmet-async';
import { JsonLd } from 'react-schemaorg';
import { type WebSite, type SearchAction } from 'schema-dts';
import { FEATURE_FLAGS, FH_SITE_NAME, MAX_DESCRIPTION_LENGTH, MAX_TITLE_LENGTH, SITE_NAME } from '../../../../constants/general';
import { DEFAULT_OPEN_GRAPH_IMAGE_ID, OPEN_GRAPH_IMAGE_OPTIONS } from '../../../../constants/images';
import { SITE_PATH, FH_SITE_PATH } from '../../../../constants/links';
import { generateCloudinaryUrl } from '../../../../helpers/cloudinary-helper/cloudinary-helper';
import { truncateString } from '../../../../helpers/general-helper/general-helper';
import { replaceSiteName } from '../../../../helpers/site-helper/site.helper';
import { useIsBuildDomain } from '../../../../hooks/apollo/site/site.hooks';
import { useFeature } from '../../../../hooks/features/features.hooks';
import { type Breadcrumb, Breadcrumbs } from '../../../breadcrumbs/breadcrumbs.component';
import { UniversalLocationIndicator } from '../../../header-components/universal-location-indicator/universal-location-indicator.component';
import { PageStructuredData } from '../page-structured-data/page-structured-data.component';
import { breadcrumb } from './page-container.css';
import { isProductPage, type PageTypes } from './page-container.types';

export type MetaProps = DetailedHTMLProps<MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>;

export type PageContainerProps<T = {}> = {
	canonicalURL: string; // used to set the rel canonical
	children: JSX.Element; // contents of the page
	metaDescription: string; // used to populate the meta description
	pageTitle: string; // used to populate the title tag
	shouldBlockIndexing?: boolean; // used to control the meta robots directive
	automationHook?: string; // add data-automation tag for QA
	breadcrumbs?: Breadcrumb[];
	breadcrumbRowItem?: React.JSX.Element;
	showUpdateLocation?: boolean;
	layout?: 'minimized';
	// for the omni theme, use 'omni-border-color' to apply 'b--theme-grey-light', otherwise the default is white
	borderStyle?: 'b--theme-grey-light' | 'b--theme-white' | 'omni-border-color';
	showBorder?: boolean;
} & T;

type QueryAction = SearchAction & {
	'query-input': string;
};

/**
 * Accepts a pageTypeProps object and calls a typeguard function and returns an
 * array of meta tags specific to that page type.  As more page types are
 * defined this function will give an easy way to map those types to meta tags
 */
const pageSpecificMetaTags = (pageTypeProps: PageTypes) => {
	if (isProductPage(pageTypeProps)) {
		return [
			{ property: 'og:type', content: 'product' },
			{ property: 'og:image', content: generateCloudinaryUrl(pageTypeProps.imagePublicId, OPEN_GRAPH_IMAGE_OPTIONS) },
			{ property: 'product:price:currency', content: 'USD' },
			{ property: 'product:price:amount', content: `${pageTypeProps.price}` }
		];
	}
	return [
		{ property: 'og:type', content: 'website' },
		{ property: 'og:image', content: generateCloudinaryUrl(DEFAULT_OPEN_GRAPH_IMAGE_ID, OPEN_GRAPH_IMAGE_OPTIONS) }
	];
};

const BreadcrumbSection: FunctionComponent<{ breadcrumbs: Breadcrumb[] | undefined; breadcrumbRowItem?: React.JSX.Element }> = ({
	breadcrumbs,
	breadcrumbRowItem
}) => (
	<div className="flex">
		{breadcrumbs && breadcrumbs.length ? (
			<div className={`flex flex-nowrap overflow-auto pr3 ${breadcrumb}`}>
				<div className="pl1 pl0-ns" style={{ flex: '0 0 auto' }}>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</div>
			</div>
		) : null}
		{breadcrumbRowItem && <div className="self-center ml-auto dn db-ns">{breadcrumbRowItem}</div>}
	</div>
);

/**
 * Handles meta tag data including open graph tags as well as structured data json-ld scripts
 * Pass a PageType generic type when using to get proper typing for that page meta data
 * Example: <PageContainer<ProductPageType> ... />
 *
 * @returns properly styled page container with Helmet for consistent meta tags.
 */
export const PageContainer = <T extends PageTypes>({
	canonicalURL,
	children,
	metaDescription,
	pageTitle,
	automationHook,
	shouldBlockIndexing = false,
	layout,
	breadcrumbs,
	breadcrumbRowItem,
	showUpdateLocation = false,
	borderStyle = 'b--theme-grey-light',
	...pageTypeProps
}: PageContainerProps<T>) => {
	const isBuild = useIsBuildDomain();

	const updatedPageTitle = isBuild ? pageTitle : replaceSiteName(pageTitle);
	const updatedDescription = isBuild ? metaDescription : replaceSiteName(metaDescription);
	const updatedSiteName = isBuild ? SITE_NAME : FH_SITE_NAME;

	const truncatedPageTitle = truncateString(updatedPageTitle, MAX_TITLE_LENGTH);
	const truncatedMetaDescription = truncateString(updatedDescription, MAX_DESCRIPTION_LENGTH);

	const useOmniHomeCanonical = useFeature(FEATURE_FLAGS.OMNI_HOME_CANONICAL_LINK);
	const baseCanonical = useOmniHomeCanonical ? FH_SITE_PATH : SITE_PATH;
	const schemaCanonical = isBuild ? SITE_PATH : FH_SITE_PATH;

	const openGraphMetaTags = [
		{ property: 'og:title', content: truncatedPageTitle },
		{ property: 'og:site_name', content: updatedSiteName },
		{ property: 'og:description', content: truncatedMetaDescription },
		...pageSpecificMetaTags(pageTypeProps)
	];
	if (canonicalURL) {
		openGraphMetaTags.push({ property: 'og:url', content: baseCanonical + canonicalURL });
	}

	// sets up meta tags
	const metaTagsData: MetaProps[] = [
		{ charSet: 'utf-8' },
		{ name: 'description', content: truncatedMetaDescription },
		shouldBlockIndexing ? { name: 'robots', content: 'NOINDEX, NOFOLLOW' } : { name: 'robots', content: 'INDEX, FOLLOW' },
		...openGraphMetaTags
	].filter((tag) => tag !== null);

	// sets up link tags
	const linkTagsData = canonicalURL ? [{ rel: 'canonical', href: baseCanonical + canonicalURL }] : undefined;

	const isHomePage = canonicalURL === '/';
	const homeAction: QueryAction = {
		'@type': 'SearchAction',
		target: schemaCanonical + '/search?term={searchTermString}',
		'query-input': 'required name=searchTermString'
	};

	// The logic with this line needs to be updated once we fully cut over to the new ferguson home.
	const containerStyle =
		layout === 'minimized'
			? ''
			: ` ba-ns bg-theme-white mb3 mt3-ns ${
					borderStyle === 'omni-border-color' ? 'b--theme-grey-light' : 'page-container-border'
			  } ${borderStyle}`;

	return (
		<>
			<div className={`center-ns mw9-ns${containerStyle}`} data-automation={automationHook} data-testid="page-container">
				{breadcrumbs && (
					<div className="flex flex-column bg-theme-white pa0 pt3-ns ph3-ns">
						<BreadcrumbSection breadcrumbs={breadcrumbs} breadcrumbRowItem={breadcrumbRowItem} />
					</div>
				)}
				{showUpdateLocation && (
					<div className={`tc theme-black pa2 dn-ns${breadcrumbs ? ' bt b--theme-grey-light' : ''}`}>
						<UniversalLocationIndicator />
					</div>
				)}
				<div className={`${layout === 'minimized' ? '' : 'pa2 pa3-ns'}`}>
					<Helmet title={truncatedPageTitle} meta={metaTagsData} link={linkTagsData} htmlAttributes={{ lang: 'en' }} />
					{/* WebSite structured data for Google results Sitelinks search box
					 * https://developers.google.com/search/docs/advanced/structured-data/sitelinks-searchbox
					 */}
					{isHomePage && (
						<JsonLd<WebSite>
							item={{
								'@context': 'https://schema.org',
								'@type': 'WebSite',
								url: schemaCanonical,
								potentialAction: homeAction
							}}
						/>
					)}
					{/* Page specific structured data if applicable */}
					<PageStructuredData pageTypeProps={pageTypeProps} />
					{children}
				</div>
			</div>
		</>
	);
};
