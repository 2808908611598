import { type FunctionComponent, type PropsWithChildren, useContext } from 'react';
import { AlertNotificationContext, type AlertNotificationType } from '../../../contexts/alert-notification.context';
import { CheckCircleIcon, CloseIcon, ReportIcon, InfoIcon, LockIcon, WarningIcon } from '../../svg/icons.component';

const fontClassMap = {
	SMALL: 'f7',
	MEDIUM: 'f6',
	LARGE: 'f5',
	GLOBAL: 'f5 f4-ns'
};

const iconClassMap = {
	SMALL: 'f5',
	MEDIUM: 'f4',
	LARGE: 'f4'
};

const closeIconMarginTopClassMap = {
	SMALL: 3,
	MEDIUM: 3,
	LARGE: 6
};

const paddingClassMap = {
	SMALL: 'pa2',
	MEDIUM: 'pa2',
	LARGE: 'pa3',
	GLOBAL: 'pa4 pa5-ns'
};

const iconPaddingClassMap = {
	SMALL: 'pr2 pt2',
	MEDIUM: 'pr2 pt2',
	LARGE: 'ph3 pt3'
};

const iconPaddingTopClassMap = {
	SMALL: 7,
	MEDIUM: 8,
	LARGE: 18
};

export type AlertNotificationProps = {
	alertType: AlertNotificationType;
	size: 'SMALL' | 'MEDIUM' | 'LARGE' | 'GLOBAL';
	showCloseButton?: boolean;
	alignCenter?: boolean;
	onClose?: () => void;
	roundedDirection?: 'top' | 'all';
};

const iconsMap = {
	danger: <ReportIcon />,
	warning: <WarningIcon />,
	info: <InfoIcon />,
	success: <CheckCircleIcon />,
	grey: <LockIcon />
};

/**
 * The alert notification component should be used to display an alert banner above the header of the page.
 */
export const AlertNotification: FunctionComponent<PropsWithChildren<AlertNotificationProps>> = ({
	alertType,
	size,
	showCloseButton,
	alignCenter,
	children,
	onClose,
	roundedDirection = 'all'
}) => {
	const { closeNotification } = useContext(AlertNotificationContext);

	const closeClick = () => {
		onClose?.();

		closeNotification();
	};

	const alertBackgroundColor = alertType === 'grey' ? 'bg-theme-grey-lighter' : `bg-theme-${alertType}-light`;
	const isNotGlobal = size !== 'GLOBAL';

	const borderRadius = roundedDirection === 'top' ? 'br2 br--top' : 'br2';

	return children ? (
		<div
			className={
				// requires pattern in postcss.config.js
				`${alertBackgroundColor} ${isNotGlobal ? 'ba b--theme-' + alertType : ''} flex ${
					isNotGlobal ? 'items-stretch ' + borderRadius : paddingClassMap[size] + ' items-center'
				}`
			}
			data-testid="alert-notification">
			{isNotGlobal && (
				<div
					className={`theme-white ${roundedDirection === 'top' ? '' : 'br2 br--left'} bg-theme-${alertType} ${
						iconClassMap[size]
					} ph2 ${iconPaddingClassMap[size]}`}
					style={{
						paddingTop: iconPaddingTopClassMap[size],
						transform: 'translateX(-1px)',
						borderTopLeftRadius: roundedDirection === 'top' ? '0.25rem' : undefined
					}}>
					{iconsMap[alertType]}
				</div>
			)}
			<div
				className={
					// requires pattern in postcss.config.js
					`pt w-100 ma0 ${alignCenter ? 'tc' : ''} theme-${alertType === 'grey' ? 'grey-dark' : alertType} ${
						isNotGlobal ? paddingClassMap[size] + ' lh-copy self-start' : ''
					} ${fontClassMap[size]}`
				}>
				{children}
			</div>
			{showCloseButton && (
				<button
					onClick={closeClick}
					type="button"
					className={`close pa0 b--none bg-transparent pointer flex ${
						isNotGlobal ? iconPaddingClassMap[size] + ' lh-copy self-start ml5' : 'items-center justify-center ml3'
					}`}
					data-dismiss="alert"
					aria-label="Close"
					style={isNotGlobal ? { marginTop: closeIconMarginTopClassMap[size] } : undefined}>
					<CloseIcon className={`${isNotGlobal ? 'f7' : fontClassMap[size]} theme-${alertType}`} />
				</button>
			)}
		</div>
	) : null;
};

/**
 * An alert notification that is fixed at the top of the window.
 */
export const GlobalAlertNotification: FunctionComponent = () => {
	const { message, type } = useContext(AlertNotificationContext);
	return message ? (
		<div className={`fixed top-0 left-0 w-100 z-5`} data-testid={'global-alert-notification'}>
			<AlertNotification alertType={type} size={'GLOBAL'} showCloseButton={true} alignCenter={true}>
				{message}
			</AlertNotification>
		</div>
	) : null;
};
