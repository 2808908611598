import { type FunctionComponent } from 'react';
import { useIsBuildDomain } from '../../../hooks/apollo/site/site.hooks';
import { Badge } from '../../common-components/badge/badge.component';
import { ProBadgeRenderer } from '../../common-components/pro-badge-renderer/pro-badge-renderer.component';
import { ArrowDropDownIcon, PersonIcon } from '../../svg/icons.component';
import { accountToggleProBadge, omniAccountProBadge } from './account-dropdown-toggle.css';

export type AccountDropdownToggleProps = {
	firstName?: string;
	isPro?: boolean;
	isOpen?: boolean;
	unreadNotificationCount: number;
};

export const AccountDropdownToggle: FunctionComponent<AccountDropdownToggleProps> = ({
	firstName,
	isPro = false,
	isOpen = false,
	unreadNotificationCount
}) => {
	const isBuild = useIsBuildDomain();
	let theme: string;

	if (!isBuild) {
		theme = isOpen ? 'omniMenuOpenBorder' : 'omniHeaderDropdownButton';
	} else {
		theme = isOpen ? 'theme-primary' : 'theme-secondary';
	}

	let accountText = 'Account';

	if (!firstName) {
		accountText = 'Orders & Returns';
	} else if (isBuild) {
		accountText = 'My Account';
	}

	const proBadgeClasses = !isBuild ? omniAccountProBadge : `${accountToggleProBadge} pt1`;

	return (
		<div className={`hover-theme-primary ${theme} flex items-center pointer`} data-tracking="nav:menu:My Account">
			{!isPro && isBuild && <PersonIcon className="f1" />}
			<div className={`flex-column ${isBuild ? 'ml2' : 'ml0'}`}>
				<span className="f6 omniAccountGreeting">
					{firstName ? (
						<>
							{!isBuild ? 'Hi' : 'Hello'}, {firstName}
						</>
					) : (
						<>Log In</>
					)}
				</span>
				<div className={`f5 flex flex-row items-center ${!isBuild && 'b truncate f6 lh-copy relative'}`}>
					{!isBuild && unreadNotificationCount > 0 && (
						<div className="db-m dn-l mr1">
							<Badge count={unreadNotificationCount} hideCount={true} noMargin={true} />
						</div>
					)}
					{isPro && <ProBadgeRenderer className={`${proBadgeClasses} pr1 f7  theme-pro`} />}
					<p className="truncate mv0">{accountText}</p>
					<ArrowDropDownIcon className="v-mid omniHome-dn omniHome-dib-l omni-f4-ns" />
				</div>
			</div>
		</div>
	);
};
