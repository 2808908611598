import loadable from '@loadable/component';
import { type FunctionComponent, type PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';
import { FOOTER_ANCHOR_CLASSES } from '../../../constants/layout';
import { AlertNotificationProvider } from '../../../contexts/alert-notification.context';
import { CompareProvider } from '../../../contexts/compare/compare.context';
import { ConstructProvider } from '../../../contexts/construct/construct.context';
import { ImpressionsProvider } from '../../../contexts/impressions/impressions.context';
import { isChromatic } from '../../../helpers/general-helper/general-helper';
import { useIsBuildDomain } from '../../../hooks/apollo/site/site.hooks';
import { useUsabilla } from '../../../hooks/usabilla/usabilla.hooks';
import { GlobalAlertNotification } from '../../common-components/alert-notification/alert-notification.component';
import { DynamicYieldPlacement } from '../../dynamic-yield/placement/dynamic-yield-placement.component';
import { Header } from '../../header-components/header/header.component';
import { OmniHomeHeader } from '../../header-components/omni-home-header/omni-home-header.component';
import { LoadableInternalMenu } from '../../internal-menu/loadable-internal-menu.component';
import { LoadableLiveChat } from '../../live-chat/loadable-live-chat.component';
import { CompareDrawerButton } from '../../product-components/compare-drawer-button/compare-drawer-button.component';
import { SkipLink } from '../../skip-link/skip-link.component';

const LoadableFooter = loadable(() => import(/* webpackChunkName: "footer" */ '../../footer-components/footer/footer.component'), {
	resolveComponent: ({ FooterContent }) => FooterContent
});

const Footer: FunctionComponent = () => {
	useUsabilla();

	const { ref, inView } = useInView({
		triggerOnce: true,
		rootMargin: '40px'
	});

	return (
		<footer ref={ref} className="lh-copy bg-theme-white omni-bg-theme-grey-lighter dn-p" data-testid="footerComponent">
			{(inView || isChromatic()) && <LoadableFooter />}
		</footer>
	);
};

const MAIN_CONTENT_ID = 'main-content';

const SwitchHeader: FunctionComponent = () => {
	const isBuild = useIsBuildDomain();

	if (!isBuild) {
		return <OmniHomeHeader />;
	}

	return <Header />;
};

export type DefaultLayoutProps = { backgroundStyle?: 'default' | 'grey' };
export const DefaultLayout: FunctionComponent<PropsWithChildren<DefaultLayoutProps>> = ({ children, backgroundStyle = 'default' }) => (
	<CompareProvider>
		<AlertNotificationProvider>
			<ConstructProvider>
				<ImpressionsProvider>
					<div
						className={backgroundStyle === 'default' ? 'omni-layout-background-color' : 'bg-theme-grey-lighter'}
						data-testid="background-container">
						<SkipLink skipTo={MAIN_CONTENT_ID}>Skip to main content</SkipLink>
						<div id="modal-root"></div>
						<div id="drawer-root"></div>
						<GlobalAlertNotification />
						<div className={FOOTER_ANCHOR_CLASSES}>
							<div>
								<SwitchHeader />
								<main id={MAIN_CONTENT_ID}>
									<>
										{children}
										{/* the block below is increasing the bundle size by 4k because it is being used across different pages */}
										<section className="center-ns mw9-ns bg-theme-white mt3 mb3 pa0">
											<DynamicYieldPlacement dynamicYieldId="dy-recs-recently-viewed" />
										</section>
									</>
								</main>
							</div>
							<Footer />
						</div>
						<LoadableLiveChat />
						<CompareDrawerButton />
						<LoadableInternalMenu />
					</div>
				</ImpressionsProvider>
			</ConstructProvider>
		</AlertNotificationProvider>
	</CompareProvider>
);
