import { type FunctionComponent, type HTMLAttributes, useState } from 'react';
import { type Maybe, type ProductPricingDisplayEnum } from '../../../__generated__/graphql-client-types';
import { FEATURE_FLAGS } from '../../../constants/general';
import { formatPrice } from '../../../helpers/general-helper/general-helper';
import { useSiteViewPreference } from '../../../hooks/apollo/employee/employee.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { type ProductDiscount, type ProductPackaging, type ProductPriceInfo, type ProductUnitPrice } from '../../../types/product.types';
import { Popover } from '../../common-components/popover/popover.component';
import { ProPriceBadgeRenderer } from '../../common-components/pro-badge-renderer/pro-badge-renderer.component';
import { InternalPriceTooltip } from '../../internal-price-tooltip/internal-price-tooltip.component';
import { ProBadgeSaleSvg } from '../../svg/general.component';
import { HelpCircleIcon } from '../../svg/icons.component';
import { popOverContent, proBadgeSale, proPricingBadge, unitFontSize } from './product-price.css';

export type ProductPriceProps = Partial<ProductPriceInfo> &
	Pick<HTMLAttributes<HTMLElement>, 'className'> & {
		variantId?: number;
		pricingDisplay?: ProductPricingDisplayEnum;
		displayTooltip?: boolean;
		isStickyNav?: boolean;
	};

export type PackagePricingProps = {
	price: number;
	expirationDate?: string;
	className?: string;
	isStickyNav?: boolean;
} & ProductPackaging;

export const ProductPrice: FunctionComponent<ProductPriceProps> = ({
	className = '',
	current,
	packaging,
	unitPrice,
	discount,
	variantId,
	pricingDisplay,
	displayTooltip = true,
	isStickyNav = false
}) => {
	const isContactForPricing = pricingDisplay === 'CALL_FOR_PRICING';
	const isContactForPricingActive = useFeature(FEATURE_FLAGS.MANAGED_PURCHASE_DISPLAY);
	const { showAsEmployee } = useSiteViewPreference();
	if (!current) return null;

	return (
		<div data-testid="product-price" className={`flex flex-row flex-nowrap justify-start ${className}`.trim()}>
			{isContactForPricing && isContactForPricingActive && !showAsEmployee ? (
				<h3>Contact For Pricing</h3>
			) : (
				<>
					{unitPrice && packaging ? (
						<SquareFootPricing unitPrice={unitPrice} packaging={packaging} price={current} isStickyNav={isStickyNav} />
					) : (
						<Pricing discount={discount} price={current} isStickyNav={isStickyNav} />
					)}
					{variantId && displayTooltip && (
						<div className={`ml2 self-end pb1 lh-copy`}>
							<InternalPriceTooltip variantId={variantId} />
						</div>
					)}
				</>
			)}
		</div>
	);
};

export type ProductPriceRangeProps = {
	min: number;
	max?: Maybe<number>;
	squareFootageBased?: boolean;
} & Pick<HTMLAttributes<HTMLElement>, 'className'>;

/**
 * Price Range Component
 *
 * @param {ProductPriceRangeProps} props
 */
export const PriceRange: FunctionComponent<ProductPriceRangeProps> = ({ className = 'f5 b', min, max, squareFootageBased = false }) => {
	return (
		<div className={className} data-testid="price-range">
			<strong>{formatPrice(min)}</strong>
			{max && max > min ? (
				<>
					<span className="mh1">-</span>
					<strong>{formatPrice(max)}</strong>
				</>
			) : null}
			{squareFootageBased ? <strong className="ml1">/ sq ft</strong> : null}
		</div>
	);
};

const SquareFootPricing: FunctionComponent<{
	unitPrice: ProductUnitPrice;
	packaging: ProductPackaging;
	price: number;
	isStickyNav?: boolean;
}> = ({ price, unitPrice, packaging, isStickyNav = false }) => {
	const { showAsEmployee } = useSiteViewPreference();
	if (isStickyNav) {
		return <PackagePricing {...packaging} price={price} isStickyNav={isStickyNav} />;
	}
	switch (unitPrice.discount?.type) {
		case 'PRO_SALE':
			return (
				<div className="flex flex-column">
					<div className="flex items-center">
						<ProBadgeSaleSvg className={`${proBadgeSale} self-center theme-pro`} />
						{showAsEmployee && unitPrice.discount.formattedExpirationDate ? (
							<span className="self-end pb1 lh-title normal theme-grey-darker f7 ml2">
								Exp. {unitPrice.discount.formattedExpirationDate.formatted}
							</span>
						) : null}
					</div>
					<div className="flex flex-wrap flex-column flex-row-ns">
						<ProSaleUnitPricing price={unitPrice.price} units={unitPrice.units} discount={unitPrice.discount} />
						<PackagePricing {...packaging} price={price} className={`theme-pro`} />
					</div>
				</div>
			);
		case 'CONSUMER_SALE':
			return (
				<div className="flex flex-wrap flex-column flex-row-ns">
					<ConsumerSaleUnitPricing price={unitPrice.price} units={unitPrice.units} discount={unitPrice.discount} />
					<PackagePricing
						{...packaging}
						price={price}
						expirationDate={showAsEmployee ? unitPrice.discount.formattedExpirationDate?.formatted : undefined}
						className={`theme-error`}
					/>
				</div>
			);
		case 'PRO_SAVINGS':
			return <ProSquareFootSavingsContainer unitPrice={unitPrice} packaging={packaging} price={price} />;
		default:
			return (
				<>
					<UnitPricing {...unitPrice} />
					<PackagePricing {...packaging} price={price} />
				</>
			);
	}
};

const Pricing: FunctionComponent<{
	price: number;
	discount?: Maybe<ProductDiscount>;
	isStickyNav?: boolean;
}> = ({ price, discount, isStickyNav }) => {
	if (isStickyNav) {
		return <PriceLabel price={price} className="b" />;
	}
	switch (discount?.type) {
		case 'PRO_SALE':
			return <ProSalePriceLabel price={price} discount={discount} />;
		case 'CONSUMER_SALE':
			return <ConsumerSalePriceLabel price={price} discount={discount} />;
		case 'PRO_SAVINGS':
			return <ProPriceLabel price={price} discount={discount} />;
		default:
			return <PriceLabel price={price} className="b" attributes={{ 'data-automation': 'price' }} />;
	}
};

const PriceLabel: FunctionComponent<{ price: number; className?: string; attributes?: Record<string, any> }> = ({
	price,
	className = 'normal',
	attributes = {}
}) => (
	<span {...attributes} className={`${className} lh-copy`}>
		{formatPrice(price)}
	</span>
);

const HelpText: FunctionComponent<{ previousAmount: number }> = ({ previousAmount }) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className="f5 lh-copy">
			Was <span className="strike">{formatPrice(previousAmount)}</span>
			<Popover isVisible={isOpen} setIsVisible={setIsOpen} toggleElement={<HelpCircleIcon className="f7 ml1 pointer" />}>
				<div className={`${popOverContent}`}>
					<div className="b f4 pa2 bg-theme-grey-lighter">Was Price Info</div>
					<div className="f6 pa2">
						The &quot;was&quot; price is a non-sale price that we offered to our customers in the last 90 days or a price that
						was offered by a competitor for the same item or a similar item in the last 90 days.
						<br />
						<br />
						We cannot guarantee that there were actual sales of the item at this price. Our non-sale prices are set through
						dynamic pricing, in which prices may change frequently in response to market conditions.
					</div>
				</div>
			</Popover>
		</div>
	);
};

const ProSalePriceLabel: FunctionComponent<{
	price: number;
	className?: string;
	discount: ProductDiscount;
	isStickyNav?: boolean;
}> = ({ price, className = 'normal', discount, isStickyNav }) => {
	const { showAsEmployee } = useSiteViewPreference();
	return (
		<div className={`${className} lh-copy`}>
			{isStickyNav ? (
				<span className="lh-title b theme-black f4 f3-ns" data-automation="price">
					{formatPrice(price)}
				</span>
			) : (
				<>
					<div className="flex flex-wrap items-center">
						<ProBadgeSaleSvg className={`${proBadgeSale} self-center mr2 theme-pro`} />
						<HelpText previousAmount={discount.previousAmount} />
					</div>
					<div className="flex flex-wrap">
						<span className="lh-copy b theme-pro f4 f3-ns" data-automation="price">
							{formatPrice(price)}
						</span>
						<div className="b lh-copy theme-grey-darker ml2 f5 self-center">
							<div>Save {discount.amount}%</div>
						</div>
						{showAsEmployee && discount.formattedExpirationDate ? (
							<span className="self-end pb1 lh-copy normal theme-grey-darker f7 ml2">
								Exp. {discount.formattedExpirationDate.formatted}
							</span>
						) : null}
					</div>
				</>
			)}
		</div>
	);
};

const ConsumerSalePriceLabel: FunctionComponent<{
	price: number;
	className?: string;
	discount: ProductDiscount;
	isStickyNav?: boolean;
}> = ({ price, className = 'normal', discount, isStickyNav = false }) => {
	const { showAsEmployee } = useSiteViewPreference();
	return (
		<div className={`${className} lh-copy`}>
			{isStickyNav ? (
				<span className="lh-title b theme-black f4 f3-ns" data-automation="price">
					{formatPrice(price)}
				</span>
			) : (
				<>
					<div className="flex items-center">
						<span className="f5 lh-copy ph2 b theme-white bg-theme-error br2 tc mr2">Sale</span>
						<HelpText previousAmount={discount.previousAmount} />
					</div>
					<div className="flex flex-wrap">
						<span className="lh-title b theme-error f4 f3-ns" data-automation="price">
							{formatPrice(price)}
						</span>
						<span className="lh-title normal theme-grey-darker f4 f3-ns ml2">{discount.amount}% off</span>
						{showAsEmployee && discount.formattedExpirationDate ? (
							<span className="self-end pb1 lh-title normal theme-grey-darker f7 ml2">
								Exp. {discount.formattedExpirationDate.formatted}
							</span>
						) : null}
					</div>
				</>
			)}
		</div>
	);
};

const ProPriceLabel: FunctionComponent<{ price: number; discount: ProductDiscount; className?: string; isStickyNav?: boolean }> = ({
	price,
	className = 'normal',
	isStickyNav
}) => {
	return (
		<div className={`${className} lh-copy`}>
			<div className="flex flex-wrap">
				<span className="lh-title b theme-pro f4 f3-ns mr2" data-automation="price">
					{formatPrice(price)}
				</span>
				{!isStickyNav && <ProPriceBadgeRenderer className={`self-center ${proPricingBadge} theme-pro`} />}
			</div>
		</div>
	);
};

const ProSaleUnitPricing: FunctionComponent<{ price: number; units: string; discount: ProductDiscount }> = ({ price, units, discount }) => {
	return (
		<div className={`flex flex-column items-start w-auto pr3 mr3 bb bb-0-ns br-ns b--theme-grey-light`}>
			<div className="flex items-center">
				<span className="f5 lh-copy normal">
					Was <span className="strike">{formatPrice(discount.previousAmount)}</span>
				</span>
				<span className="b lh-title theme-grey-darker ml2 f5">Save {discount.amount}%</span>
			</div>
			<ProPerUnitSection price={price} units={units} />
		</div>
	);
};

const ProUnitPricing: FunctionComponent<{ price: number; units: string }> = ({ price, units }) => {
	return (
		<div className={`flex flex-column items-start w-auto pr3 mr3 bb bb-0-ns br-ns b--theme-grey-light`}>
			<div className="flex items-center mt1">
				<ProPriceBadgeRenderer className={`self-center ${proPricingBadge} theme-pro`} />
			</div>
			<ProPerUnitSection price={price} units={units} />
		</div>
	);
};

const ProPerUnitSection: FunctionComponent<{ price: number; units: string }> = ({ price, units }) => {
	return (
		<div className={`flex items-center flex-wrap w-auto pr3`}>
			<PriceLabel price={price} className={`b theme-pro`} />
			<div className={`ml2 theme-grey ${unitFontSize}`}>per {units}</div>
		</div>
	);
};

const ConsumerSaleUnitPricing: FunctionComponent<{ price: number; units: string; discount: ProductDiscount }> = ({
	price,
	units,
	discount
}) => {
	return (
		<div className={`flex flex-column items-start w-auto pr3 mr3 bb bb-0-ns br-ns b--theme-grey-light`}>
			<div className="flex items-center">
				<span className="f5 lh-copy ph2 b theme-white bg-theme-error br2 tc mr2">Sale</span>
				<HelpText previousAmount={discount.previousAmount} />
			</div>
			<div className="flex items-center flex-wrap">
				<PriceLabel price={price} className={`b theme-error`} />
				<div className={`ml2 theme-grey ${unitFontSize}`}>per {units}</div>
			</div>
		</div>
	);
};
const UnitPricing: FunctionComponent<ProductUnitPrice> = ({ price, units }) => {
	return (
		<div className={`flex flex-column items-start w-auto pr3 mr3 br bw1 b--theme-grey-light`}>
			<PriceLabel price={price} className={`b`} />
			<div className={`dib theme-grey ${unitFontSize}`}>per {units}</div>
		</div>
	);
};

const PackagePricing: FunctionComponent<PackagePricingProps> = ({
	name,
	value,
	units,
	price,
	expirationDate,
	className = '',
	isStickyNav = false
}) => {
	return (
		<div className={`flex flex-column ${isStickyNav ? 'items-end' : 'items-start'} w-auto`}>
			<PriceLabel price={price} className={className} attributes={{ 'data-automation': 'price' }} />
			<div className={`dib theme-grey ${unitFontSize} ${isStickyNav ? 'fw3' : ''}`}>
				per {name.toLowerCase()} ({value} {units})
				{expirationDate ? (
					<span className={`pb1 lh-title normal theme-grey-darker ${isStickyNav ? 'ml' : 'f7 ml2'}`}>Exp. {expirationDate}</span>
				) : null}
			</div>
		</div>
	);
};

const ProSquareFootSavingsContainer: FunctionComponent<{
	unitPrice: ProductUnitPrice;
	packaging: ProductPackaging;
	price: number;
}> = ({ unitPrice, packaging, price }) => {
	return (
		<div className="flex flex-column">
			<div className="flex flex-wrap flex-column flex-row-ns">
				<ProUnitPricing price={unitPrice.price} units={unitPrice.units} />
				<PackagePricing {...packaging} price={price} className={`theme-pro`} />
			</div>
		</div>
	);
};
