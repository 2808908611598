import { type FunctionComponent, type PropsWithChildren } from 'react';
import { Link, type LinkProps } from './link.component';
import type { LinkStyleProps } from './link.types';

export type StyledLinkProps = PropsWithChildren<LinkStyleProps & LinkProps>;

/**
 * A Link that supports visual enhancements (color, underline etc.)
 */
export const StyledLink: FunctionComponent<StyledLinkProps> = ({ color = 'primary', className = '', underline, ...restProps }) => {
	// explicitly set underline to none for omnihome since some link colors are underlined by default
	const underlineStyle = underline === false ? `link-no-underline` : '';
	return (
		<Link
			isStyledLink
			className={`link-${color} ${underlineStyle} ${className}`.trim()}
			color={color}
			underline={underline}
			{...restProps}
		/>
	);
};
