import { type FunctionComponent } from 'react';
import { FEATURE_FLAGS } from '../../../constants/general';
import { useFeature } from '../../../hooks/features/features.hooks';
import { OmniProBadgeIconSvg, OmniProPriceBadgeSvg, ProBadgeSvg, ProPriceBadgeSvg } from '../../svg/general.component';

export type ProBadgeRendererProps = { className?: string };

export const ProBadgeRenderer: FunctionComponent<ProBadgeRendererProps> = ({ className }) => {
	const isOmniHomeLogoSchedulerOn = useFeature(FEATURE_FLAGS.OMNI_HOME_LOGO_SCHEDULER);
	return isOmniHomeLogoSchedulerOn ? <OmniProBadgeIconSvg className={className} /> : <ProBadgeSvg className={className} />;
};

type ProPriceBadgeRendererProps = { className?: string };

export const ProPriceBadgeRenderer: FunctionComponent<ProPriceBadgeRendererProps> = ({ className }) => {
	const isOmniHomeLogoSchedulerOn = useFeature(FEATURE_FLAGS.OMNI_HOME_LOGO_SCHEDULER);
	return isOmniHomeLogoSchedulerOn ? <OmniProPriceBadgeSvg className={className} /> : <ProPriceBadgeSvg className={className} />;
};
